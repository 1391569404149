import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Manifest } from "@/types/event";
import {
  getManifestBackup,
  clearManifestBackup,
  saveManifestBackup,
} from "@/utils/localStorageBackup";
import { amountDisplay } from "@/utils/money";
import { Column, Table } from "@/components/AppTable2";
import { useEventManifests } from "@/hooks/useEventManifests";
import { toast } from "react-toastify";

const EventManifestTable: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<Manifest[]>([]);

  const rowClassName = "text-sm bg-gray-100 h-8";
  const inputClassName =
    "hover:bg-orange-400 duration-200 bg-transparent p-0.5 w-full text-sm h-8";
  const headerCustomClasses = "bg-gray-300 text-sm";

  const params = useParams<{ id: string }>();
  const eventId = params.id as string;

  const {
    manifests,
    isLoading,
    isError,
    error,
    addManifest,
    updateManifest,
    deleteManifest,
  } = useEventManifests(eventId);

  useEffect(() => {
    // Check for backup on component mount
    const backup = getManifestBackup(eventId);
    if (backup && !manifests?.length) {
      toast.info("Found unsaved manifest data. Click here to restore.", {
        onClick: () => {
          setRows([backup.data]);
          clearManifestBackup(eventId);
        },
      });
    }

    if (manifests) {
      const preparedRows = manifests
        .map((manifest) => ({
          ...manifest,
          gross_price: amountDisplay(
            manifest.price + (manifest.ticket_fees || 0)
          ),
          potential: amountDisplay(
            (manifest.price + (manifest.ticket_fees || 0)) *
              (manifest.qty - manifest.comps)
          ),
        }))
        .sort((a, b) => a.sort_order - b.sort_order);
      setRows(preparedRows);
    }
  }, [manifests]);

  const handleAddManifest = async (
    newRow: Record<string, any>
  ): Promise<{ success: boolean; preserveRow?: Record<string, any> }> => {
    try {
      console.log("Adding new manifest with data:", newRow);

      if (!newRow) {
        toast.error("No form data received");
        return { success: false };
      }

      // Save backup before attempting submission
      saveManifestBackup(eventId, newRow);

      const name = String(newRow.name || "").trim();
      console.log("Processed name:", name, "Length:", name.length);

      if (!name) {
        toast.error("Please enter a name for the manifest");
        return { success: false, preserveRow: newRow };
      }

      const manifestData = {
        name,
        event: parseInt(eventId),
        qty: parseInt(String(newRow.qty)) || 0,
        comps: parseInt(String(newRow.comps)) || 0,
        price: String(newRow.price || 0),
        ticket_fees: String(newRow.ticket_fees || 0),
        sort_order: rows.length,
        active: true,
      };

      console.log("Manifest data to be sent:", manifestData);
      try {
        await addManifest(manifestData);
        clearManifestBackup(eventId); // Clear backup after successful save
        //toast.success("Manifest added successfully");
        return { success: true };
      } catch (error) {
        // If API call fails, keep the backup and show error
        toast.error("Failed to save manifest. Data backed up locally.");
        return { success: false, preserveRow: newRow };
      }
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "Failed to add manifest";
      toast.error(message);
      console.error("Error adding manifest:", error);
      return { success: false, preserveRow: newRow };
    } finally {
      setLoading(false);
    }
  };

  const handleCellChange = async (
    rowIndex: number,
    accessor: string,
    value: any,
    row: Manifest
  ) => {
    try {
      setLoading(true);
      console.log("handleCellChange - row:", row);
      console.log("handleCellChange - attempting update with:", {
        id: row.id,
        accessor,
        value,
      });

      if (!row.id) {
        console.error("Missing row ID:", row);
        toast.error("Unable to update: Missing row ID");
        return;
      }

      const updates = {
        [accessor]:
          accessor === "qty" || accessor === "comps" ? Number(value) : value,
      };

      console.log("Updating manifest with:", { id: row.id, updates });
      await updateManifest({ id: row.id, updates });
    } catch (error: any) {
      console.error("Error updating manifest:", error);
      toast.error("Failed to update manifest.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (_: any, rowData: Manifest) => {
    const manifestId = rowData.id;
    if (!manifestId) {
      toast.error("Invalid manifest ID.");
      return;
    }
    try {
      setLoading(true);
      await deleteManifest(manifestId.toString());
    } catch (error: any) {
      console.error("Error deleting manifest:", error);
      toast.error("Failed to delete manifest.");
    } finally {
      setLoading(false);
    }
  };

  const handleReorder = async (reorderedRows: Record<string, any>[]) => {
    setLoading(true);
    const originalRows = [...rows];

    try {
      const mapped = reorderedRows.map((row, i) => ({
        ...row,
        sort_order: i,
      }));
      setRows(mapped);

      // Process updates sequentially with better error handling
      for (const row of mapped) {
        try {
          const updateData = {
            id: row.id,
            event: parseInt(eventId),
            name: row.name,
            sort_order: row.sort_order,
            price: row.price?.toString() || "0",
            ticket_fees: row.ticket_fees?.toString() || "0",
            qty: parseInt(row.qty) || 0,
            comps: parseInt(row.comps) || 0,
            active: true,
            is_offer: true,
          };

          await updateManifest({ id: row.id, updates: updateData });
        } catch (error) {
          console.error(`Failed to update manifest ${row.id}:`, error);
          throw error; // Re-throw to trigger the outer catch block
        }
      }

      //toast.success("Manifests reordered successfully");
    } catch (error) {
      console.error("Error reordering manifests:", error);
      setRows(originalRows); // Restore original order
      const message =
        error instanceof Error ? error.message : "Failed to reorder manifests";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const columns: Column[] = [
    {
      title: "TICKETING",
      accessor: "name",
      editable: true,
      format: "input",
      textAlign: "left",
      addRowPlaceholder: "Ticket Type",
    },
    {
      title: "CAPACITY",
      accessor: "qty",
      editable: true,
      format: "input",
      textAlign: "center",
      addRowPlaceholder: "Capacity",
    },
    {
      title: "COMPS",
      accessor: "comps",
      editable: true,
      format: "input",
      textAlign: "center",
      addRowPlaceholder: "Comps",
    },
    {
      title: "NET PRICE",
      accessor: "price",
      editable: true,
      format: "money",
      textAlign: "left",
      addRowPlaceholder: "Net Price",
    },
    {
      title: "TICKET FEES",
      accessor: "ticket_fees",
      editable: true,
      format: "money",
      textAlign: "left",
      addRowPlaceholder: "Ticket Fees",
    },
    {
      title: "GROSS PRICE",
      accessor: "gross_price",
      editable: false,
      format: "money",
      textAlign: "left",
      addRowPlaceholder: "Gross Price",
      addRender: () => <span>N/A</span>,
    },
    {
      title: "POTENTIAL",
      accessor: "potential",
      editable: false,
      format: "money",
      textAlign: "left",
      addRowPlaceholder: "Potential",
      addRender: () => <span>N/A</span>,
    },
  ];

  if (isLoading && rows.length === 0) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error loading manifests: {error?.message}</div>;
  }

  return (
    <div>
      <Table
        columns={columns}
        data={rows}
        onCellBlur={handleCellChange}
        orderable={true}
        reorderablePosition="first"
        onReorder={handleReorder}
        headerClassName={headerCustomClasses}
        rowClassName={rowClassName}
        rowContainerClass="border-b"
        inputClassName={inputClassName}
        unstyled={true}
        enableAddRow={true}
        onAddRow={handleAddManifest}
        showNoDataMessage={true}
        onDelete={handleDelete as any}
        addRowButtonTitle="Add Manifest"
        // loading={loading || isLoading}
      />
    </div>
  );
};

export default EventManifestTable;
