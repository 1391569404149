import React from "react";
import { DocumentIcon } from "@heroicons/react/24/solid";

const FileCountDisplay = ({
  fileCount,
  onClick,
  showUploadButton,
  onUploadClick,
  sectionId, // Add explicit sectionId prop
}) => {
  // Memoize the count calculation to prevent unnecessary recalculations
  const validFileCount = React.useMemo(() => {
    const componentId = Math.random().toString(36).substr(2, 9);
    //console.log(`[${componentId}] FileCountDisplay calculation starting`, {
    //  rawInput: fileCount,
    //  type: typeof fileCount,
    //  structure: fileCount?.data ? "Has data property" : "No data property",
    //  parentProps: { showUploadButton, hasClickHandler: !!onClick },
    //});

    // If we have an array of data, validate and filter it first
    if (fileCount?.data && Array.isArray(fileCount.data)) {
      // Log the full data structure to help debug
      //console.log(`[${componentId}] Data structure:`, {
      //  fullData: fileCount.data,
      //  dataTypes: fileCount.data.map((item) => typeof item),
      //  currentSectionId: sectionId,
      //});

      if (!sectionId) {
        console.warn(
          `[${componentId}] No sectionId provided, counting might be incorrect`
        );
        return 0;
      }

      // Only count files that belong to this specific section
      const filteredData = fileCount.data.filter(
        (item) => item.sectionId === sectionId || item.groupId === sectionId
      );

      const length = filteredData.length;
      //console.log(`[${componentId}] Section-specific count:`, {
      //  sectionId,
      //  count: length,
      //  matchingFiles: filteredData.map((f) => f.id || f.name),
      //});
      return length;
    }

    // If it's already a valid number, use it directly
    if (typeof fileCount === "number" && !isNaN(fileCount) && fileCount >= 0) {
      console.log(`[${componentId}] Using direct number:`, fileCount);
      return fileCount;
    }

    // Last resort - try to parse as number
    const parsed = parseInt(fileCount, 10);
    if (!isNaN(parsed) && parsed >= 0) {
      console.log(`[${componentId}] Using parsed number:`, parsed);
      return parsed;
    }

    console.warn(`[${componentId}] Invalid fileCount, defaulting to 0`, {
      received: fileCount,
      type: typeof fileCount,
    });
    return 0;
  }, [fileCount]); // Only recalculate when fileCount changes

  React.useEffect(() => {
    // Get parent component name from props or context if available
    const parentComponent = onClick?.name || onUploadClick?.name || "Unknown";
    //console.log(`[FileCountDisplay] Component Group: ${parentComponent}`, {
    //  count: validFileCount,
    //  hasUploadButton: showUploadButton,
    //  hasClickHandler: !!onClick,
    //});
  }, [validFileCount, onClick, onUploadClick, showUploadButton]);

  if (validFileCount > 0) {
    return (
      <div className="flex items-center space-x-1">
        {Array.from({ length: Math.min(validFileCount, 3) }).map((_, i) => (
          <DocumentIcon
            key={i}
            className="h-5 w-5 text-cave-blue2 hover:text-blue-500 cursor-pointer"
            onClick={onClick}
          />
        ))}
        {validFileCount > 3 && (
          <span className="text-xs text-cave-black dark:text-cave-white ml-1">
            +{validFileCount - 3}
          </span>
        )}
      </div>
    );
  }

  if (showUploadButton) {
    return (
      <button
        type="button"
        className="rounded bg-cave-blue2 px-2 py-1 text-xs font-semibold text-black shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={onUploadClick}
      >
        Upload Receipt(s)
      </button>
    );
  }

  return null;
};

export default FileCountDisplay;
