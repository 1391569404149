import React from "react";
import { useFormContext } from "react-hook-form";

export const TextInput = (props) => {
  const { name, label, helpText } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[props.name]?.message?.toString();

  return (
    <div className="my-3">
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium text-cave-black dark:text-cave-white"
      >
        {label}
      </label>
      <textarea
        {...register(name)}
        id={name}
        className="bg-gray-50 border border-gray-300 text-cave-black sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-cave-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        {...props}
      />
      {errorMessage && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
          {errorMessage}
        </p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {helpText}
        </p>
      )}
    </div>
  );
};

export default TextInput;
