import React, { useEffect, useState } from "react";
import "../../styles/loading-pulse.css";

export const LoadingPulse = ({ isLoading }) => {
  useEffect(() => {
    // Set body background to black when component mounts
    document.body.style.backgroundColor = "rgb(18, 18, 18)"; // cave-black color

    // Reset body background when component unmounts
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);
  return (
    <div
      className={`flex items-center justify-center h-screen w-screen fixed top-0 left-0 bg-cave-black z-50 transition-opacity duration-1000 ease-in-out ${
        !isLoading ? "opacity-0 pointer-events-none" : "opacity-100"
      }`}
    >
      <div className="relative w-28 h-28">
        <div className="absolute inset-0 scale-110 border-8 border-[#FFFBE6] rounded-full animate-[radialPulseOut_4s_ease-in-out_infinite] animation-play-state-preserve"></div>
        <div className="absolute inset-0 scale-110 border-8 border-[#3F3F3F] rounded-full animate-[radialPulseIn_4s_ease-in-out_infinite] animation-play-state-preserve"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src="/static/images/company-logo-black-bg.svg"
            className="absolute h-12 w-auto z-10 dark:block animate-[pulse_4s_ease-in-out_infinite] animation-play-state-preserve"
            alt="Loading"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingPulse;
