import { EventsLoading } from "@/components/Events/EventsLoading";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getEventsList, getEventsSettings } from "@/queries/events";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import EventState from "@/components/EventState";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { EventCard } from "@/components/Dashboard/EventCard/EventCard";
import { Carousel } from "@/components/Dashboard/Carousel/Carousel";

export const EventsHome = () => {
  const events = useQuery(
    ["events", "list"],
    () => getEventsList(),
    getEventsSettings
  );
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(events.isSuccess ? "/c/events" : "/c/ave_good...");

  if (events.isLoading) {
    return <EventsLoading />;
  }

  return (
    <>
      <div>
        <Header />
        <div
          className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
        >
          <div className="w-full flex justify-between xs:flex-col sm:flex-row md:flex-row items-center xs:gap-[1rem]">
            <BreadCrumbs links={[{ text: "Events", url: "/events" }]} />
            <Button onClick={() => navigate("/events/list")}>Event List</Button>
            <span>
              {perms.data.create_events && (
                <Button onClick={() => navigate("/events/add")}>
                  Add Event
                </Button>
              )}
            </span>
          </div>

          <div className="my-2 h-auto w-auto shadow-md sm:rounded-lg ">
            {events.isSuccess && (
              <div className="flex flex-wrap gap-6">
                {events.data.map((event, index) => (
                  <div
                    key={`event-${event.id}`}
                    className="bg-cave-white dark:bg-[#181818] xs:mx-auto shadow-[0_8px_30px_rgb(255,255,255,0.12)] dark:shadow-[0_8px_30px_rgba(0,0,0,0.12)] hover:shadow-[0_20px_60px_rgb(255,255,255,0.2)] dark:hover:shadow-[0_20px_60px_rgba(0,0,0,0.2)] transition-shadow duration-300"
                  >
                    <EventCard
                      eventName={event.name}
                      venue={event.venue_name}
                      date={event.date}
                      status={event.state}
                      onClick={() => {}}
                      backgroundImage={
                        event.event_image_url
                          ? event.event_image_url
                          : "/static/images/cave_no_image_event.png"
                      }
                      url={`/events/${event.id}`}
                      key={`non-draft-${event.id}`}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
