import React from "react";
import CreatableSelect from "react-select/creatable";
import { useFormContext, useController } from "react-hook-form";
import { Modal } from "../Modal/Modal";
import { useState } from "react";

export const FormCreatableSelect = (props) => {
  const {
    name,
    label,
    options,
    helpText,
    required,
    isMulti = false,
    onCreateOption,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState("");

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const errorMessage = errors[props.name]?.message?.toString();

  const {
    field: { value, onChange, onBlur, ref, ...restSelField },
  } = useController({ name: name, control: control });

  const handleCreate = (inputValue) => {
    setPendingValue(inputValue);
    setIsModalOpen(true);
  };

  const handleConfirm = async () => {
    const newOption = await onCreateOption(pendingValue);
    if (newOption) {
      if (isMulti) {
        onChange([...(value || []), newOption]);
      } else {
        onChange(newOption);
      }
    }
    setIsModalOpen(false);
  };

  return (
    <div className="my-3">
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium text-cave-black dark:text-cave-white"
      >
        {label}
      </label>
      <CreatableSelect
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isMulti={isMulti}
        ref={ref}
        required={required}
        onCreateOption={handleCreate}
        placeholder={props.placeholder}
        {...restSelField}
      />
      {errorMessage && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
          {errorMessage}
        </p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {helpText}
        </p>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        title="Create New Item"
      >
        <p>Are you sure you want to create "{pendingValue}"?</p>
      </Modal>
    </div>
  );
};
