import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getSystemMessage } from "../queries/base";

export const SystemMessage = () => {
  const { data, isError, isLoading, error } = useQuery({
    queryKey: ["systemMessage"],
    queryFn: getSystemMessage,
    refetchInterval: 60000, // Refetch every minute
    staleTime: 30000, // Consider data fresh for 30 seconds
    cacheTime: 60000, // Keep data in cache for 1 minute
  });

  console.log("SystemMessage component state:", {
    isLoading,
    isError,
    error,
    data,
  });

  if (isLoading) {
    console.log("SystemMessage is loading...");
    return null;
  }

  if (isError) {
    console.error("SystemMessage error:", error);
    return null;
  }

  if (!data?.active || !data?.message) {
    console.log("SystemMessage: No active message found", data);
    return null;
  }

  console.log("SystemMessage rendering with data:", data);

  return (
    <div
      className="fixed top-0 left-0 right-0 z-50 animate-slideDown flex items-center justify-center border-8 border-cave-white ml-96 mr-96"
      style={{
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)",
      }}
    >
      <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-4">
        <div className="flex items-center justify-center py-6">
          <div className="flex items-center space-x-6">
            <div className="flex-shrink-0 flex items-center">
              <svg
                className="h-10 w-10 text-amber-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <p
              className="text-2xl font-medium text-cave-white"
              style={{
                textShadow:
                  "2px 2px 4px rgba(0, 0, 0, 0.8), -2px -2px 4px rgba(0, 0, 0, 0.8), 2px -2px 4px rgba(0, 0, 0, 0.8), -2px 2px 4px rgba(0, 0, 0, 0.8)",
              }}
            >
              {data.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
