// SettlementArtistPayment.tsx

import React from "react";
import { TableRow } from "../AppTable2"; // Adjust the import path as needed

interface PaymentSection {
  value: string;
  onUpdate: (value: string) => void;
}

interface SettlementArtistPaymentProps {
  editable?: boolean;
  paymentMethod: PaymentSection;
  checkNumber: PaymentSection;
  paidThrough: PaymentSection;
}

export default function SettlementArtistPayment({
  checkNumber,
  paymentMethod,
  paidThrough,
  editable = true,
}: SettlementArtistPaymentProps) {
  // Memoize the sections array
  const sections = React.useMemo(
    () => [
      {
        label: "PAYMENT METHOD (check / wire)",
        id: "payment_method",
        value: paymentMethod.value ?? "",
        onUpdate: paymentMethod.onUpdate,
        format: "select",
        options: [
          { value: "check", label: "Check" },
          { value: "wire", label: "Wire" },
        ],
      },
      {
        label: "CHECK #",
        id: "check_number",
        value: checkNumber.value ?? "",
        onUpdate: checkNumber.onUpdate,
      },
      {
        label: "PAID THROUGH:",
        id: "paid_through",
        value: paidThrough.value ?? "",
        onUpdate: paidThrough.onUpdate,
      },
    ],
    [
      paymentMethod.value,
      paymentMethod.onUpdate,
      checkNumber.value,
      checkNumber.onUpdate,
      paidThrough.value,
      paidThrough.onUpdate,
    ]
  );

  return (
    <div>
      {sections.map((section) => {
        // Memoize columns to prevent unnecessary re-renders
        const columns = React.useMemo(
          () => [
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
              editable: false,
              className: "white-space-nowrap px-1",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
              editable,
              className: "h-full",
              format: section.format,
              options: section.options,
            },
          ],
          [editable, section.format, section.options]
        );

        // Memoize rowData to keep the reference stable
        const rowData = React.useMemo(
          () => ({
            name: section.label,
            value: section.value,
          }),
          [section.label, section.value]
        );

        return (
          <TableRow
            key={section.id}
            columns={columns}
            rowData={rowData}
            rowContainerClass="h-full"
            inputClassName="!border-0 hover:bg-orange-400 duration-200 h-full"
            onBlur={(
              firstField: any,
              value: string,
              thirdField: any,
              fourthField: any
            ) => {
              section.onUpdate(value);
            }}
          />
        );
      })}
    </div>
  );
}
