import React from "react";
import ExpenseTable from "../ExpensesTable";
import SettlementArtistTheDeal from "./SettlementArtistTheDeal";
import SettlementTicketManifests from "./SettlementTicketManifests";
import SettlementWireInfo from "./SettlementWireInfo";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import DealAndFinalFigures from "./SettlementArtistDealSection";

export default function SettlementArtistPage() {
  const { event, isPDF } = useSettlementComponent();
  if (!event.data) return null;
  return (
    <div>
      <SettlementTicketManifests />
      <DealAndFinalFigures />
      <ExpenseTable
        id={event.data?.id}
        book={"artist"}
        isPDF={isPDF}
        className="mb-6"
      />
      <SettlementArtistTheDeal />
      <SettlementWireInfo />
    </div>
  );
}
