import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
  getCSRFToken,
} from "./base";

const getFileApiUrl = (entityType, parentId, fileId = null) => {
  // Handle special case for 'people' which doesn't get pluralized
  // Remove 's' if it exists, then add it back unless it's 'people'
  const baseType = entityType.endsWith("s")
    ? entityType.slice(0, -1)
    : entityType;
  const apiEntity = baseType === "people" ? "people" : `${baseType}s`;
  if (fileId) {
    return `/api/${apiEntity}/file/${fileId}/`;
  }
  return `/api/${apiEntity}/files/${parentId}/`;
};

export const getFiles = async (entityType, id, marketingOnly = false) => {
  if (!id) {
    console.warn("getFiles called without an ID");
    return { data: [] };
  }

  const url = getFileApiUrl(entityType, id);
  console.log("Fetching files from URL:", url);

  const response = await makeRequest(url, "GET", await jsonHeaders());
  const data = await response.json();
  console.log("Raw API Response for ID", id, ":", data);

  if (!data) {
    console.warn("No data received from API");
    return { data: [] };
  }

  // Handle both array and object responses
  const rawData = data.data || data;
  console.log("Extracted data:", rawData);

  if (!rawData) {
    console.warn("No raw data after extraction");
    return { data: [] };
  }

  // Ensure we have an array to work with
  const dataArray = Array.isArray(rawData)
    ? rawData
    : [rawData].filter(Boolean);
  console.log("Data array:", dataArray);

  // Process each file
  const processedData = dataArray
    .filter((file) => {
      // Log raw file data
      console.log("Processing file in filter:", file);

      const hasId = Boolean(file?.id);
      const hasFile = Boolean(file?.file || file?.url);
      const isActive = file?.active === true;

      const isValid = file && hasId && hasFile && isActive;

      console.log(`File ${file?.id} validation:`, {
        hasId,
        hasFile,
        isActive,
        activeValue: file?.active,
        isValid,
      });

      return isValid;
    })
    .map((file) => {
      const processed = {
        ...file,
        id: file.id,
        file: file.file || file.url,
        name: file.name || file.filename || `Receipt ${file.id}`,
        created: file.created,
        modified: file.modified,
        active: true,
      };
      console.log(`Processed file ${file.id}:`, processed);
      return processed;
    });

  console.log("Final processed data:", processedData);
  return { data: processedData };
};

export const createFile = async (entityType, parentId, formData) => {
  console.log("createFile called with:", {
    entityType,
    parentId,
    formDataEntries: Array.from(formData.entries()),
  });

  if (!entityType || !parentId) {
    console.error("Missing required params:", { entityType, parentId });
    throw new Error("Missing required parameters");
  }

  const url = getFileApiUrl(entityType, parentId);
  console.log("Making request to URL:", url);

  const headers = await jsonHeaders(true);
  headers.delete("Content-Type"); // Required for FormData

  try {
    // Make the request with original FormData
    const resp = await makeRequest(url, "POST", headers, formData);

    if (!resp.ok) {
      const errorText = await resp.text();
      console.error("Server error response:", errorText);
      throw new Error(`Upload failed: ${resp.status} ${resp.statusText}`);
    }

    let jsonResponse;
    try {
      jsonResponse = await resp.json();
    } catch (e) {
      console.error("Error parsing response:", e);
      throw new Error("Invalid response from server");
    }
    console.log("File upload response:", jsonResponse);

    // Process and normalize the response data
    const processResponse = (data) => ({
      ...data,
      id: data.id,
      file: data.file || data.url || data.file_url || data.image_url,
      name: data.name || data.filename || `File ${data.id}`,
      created: data.created,
      modified: data.modified,
      active: data.active !== false,
      marketing: data.marketing || false,
      final: data.final || false,
    });

    if (Array.isArray(jsonResponse)) {
      return jsonResponse
        .map(processResponse)
        .filter((file) => file && file.id && file.active !== false);
    }
    return processResponse(jsonResponse);
  } catch (error) {
    console.error("Error in createFile:", error);
    throw error;
  }
};

//export const createFile = async (entityType, parentId, data) => {
//  console.log(`create${entityType}File called with parentId:`, parentId);
//  const url = entityType === "event-expenses"
//    ? `/api/accounting/event-expenses/files/${parentId}/`
//    : `/api/${entityType}/files/${parentId}/`;
//  console.log("Request URL:", url);
//
//  try {
//    const headers = await jsonHeaders(true);
//    headers.delete("Content-Type"); // Remove Content-Type for FormData
//
//    // Create a new FormData instance if raw files are passed
//    let formData = data;
//    if (!(data instanceof FormData)) {
//      formData = new FormData();
//      if (Array.isArray(data)) {
//        data.forEach(file => formData.append('files', file));
//      } else {
//        formData.append('files', data);
//      }
//    }
//
//    const resp = await makeRequest(url, "POST", headers, formData);
//
//    if (!resp.ok) {
//      let errorMessage;
//      try {
//        const contentType = resp.headers.get('content-type');
//        if (contentType?.includes('application/json')) {
//          const errorData = await resp.json();
//          errorMessage = errorData.error || errorData.detail || 'Upload failed';
//          console.error('Server error response:', errorData);
//        } else {
//          errorMessage = await resp.text() || 'Upload failed';
//        }
//      } catch (e) {
//        console.error('Error parsing error response:', e);
//        errorMessage = `Upload failed (${resp.status})`;
//      }
//      throw new Error(errorMessage);
//    }
//
//    const jsonResponse = await resp.json();
//    console.log(`create${entityType}File response:`, jsonResponse);
//    return jsonResponse;
//  } catch (error) {
//    console.error(`Error in create${entityType}File:`, error);
//    throw error;
//  }
//};

export const deleteFile = async (entityType, parentId, file) => {
  if (!file || !file.id) {
    throw new Error("Invalid file ID");
  }

  // Handle special case for 'people' which doesn't get pluralized
  // Remove 's' if it exists, then add it back unless it's 'people'
  const baseType = entityType.endsWith("s")
    ? entityType.slice(0, -1)
    : entityType;
  const apiEntity = baseType === "people" ? "people" : `${baseType}s`;
  const url = `/api/${apiEntity}/file/${file.id}/`;
  const headers = await jsonHeaders(true);

  const resp = await makeRequest(url, "DELETE", headers);
  return resp;
};
