import { CommonSettlementProps } from "@/types/settlement";
import { UseQueryResult } from "@tanstack/react-query";
import React, { useEffect } from "react";
import MoneyInputComponent from "../Common/MoneyInput";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { Table, TableRow } from "../AppTable2";
import { amountDisplay, amountStrToInt } from "@/utils/money";
import { useFinalFigures } from "@/hooks/useFinalFigures";
export default function SettlementFinalFigures() {
  const {
    handleUpdateEventSettlement,
    pageIndex,
    settlementInputClassName,
    isPDF,
  } = useSettlementComponent();
  const artistFinalFigures = useFinalFigures("artist");
  const coproFinalFigures = useFinalFigures("copro");
  const finalFinalFigures = useFinalFigures("final");
  if (!artistFinalFigures || !coproFinalFigures || !finalFinalFigures)
    return null;

  const maybeOtherPageDataToUse = (key: string) => {
    if (pageIndex === "artist") return artistFinalFigures.dataToUse(key);
    if (pageIndex === "copro") return artistFinalFigures.dataToUse(key);
    return finalFinalFigures.dataToUse(key);
  };
  const dataToUse = maybeOtherPageDataToUse;
  const handleBlur = (
    rowIndex: number,
    accessor: string,
    value: any,
    row: any
  ) => {
    const resolvedValue = value;
    const isString = isNaN(Number(value));
    if (!isString) {
      //   resolvedValue = amountStrToInt(value);
    }
    const data = { [row.key]: resolvedValue };
    handleUpdateEventSettlement({
      book: pageIndex,
      data,
      key: "rollups",
    });
  };

  const columns = [
    {
      title: "",
      accessor: "title",
      addRowEditable: true,
      width: "31%",
    },
    {
      title: "",
      accessor: "valueOne",
      addRowEditable: true,
    },
    {
      title: "RATE",
      accessor: "rate",
      editable: (row, col) => {
        if (pageIndex !== "artist") return false;
        const rowKeysAllowed = ["charity", "other", "tax1", "tax2"];
        if (rowKeysAllowed.includes(row.key) && col.accessor === "rate") {
          return true;
        }
        return false;
      },
      addRowEditable: true,
    },
    {
      title: "TOTAL",
      accessor: "total",
      textAlign: "right",
      format: "money",
      //   editable: (r, c) => {
      //     if (r.key === "platinum_lift" && c.accessor === "total") {
      //       console.log("r.key, c.accessor", r.key, c.accessor);
      //       return true;
      //     }
      //     return false;
      //     console.log("r", r, c);
      //   },
      editable: ["artist", "final"].includes(pageIndex),
    },
  ];
  const TxtGrossRows = [
    {
      title: "PLATINUM LIFT",
      key: "platinum_lift",
      valueOne: 0,
      rate: 0,
      total: dataToUse("platinum_lift"),
    },
    {
      title: "Other",
      key: "other",
      valueOne: 0,
      rate: 0,
      total: 0,
    },
  ];

  const FacilityFeeRows = [
    {
      title: "FACILITY FEE(S)",
      key: "facility_fee",
      valueOne: 0,
      rate: "",
      total: dataToUse("facility_fee") || "-",
    },
    {
      title: "CHARITY",
      key: "charity",
      valueOne: 0,
      rate: 0,
      total: dataToUse("charity") || "-",
    },
    {
      title: "OTHER",
      key: "other",
      valueOne: 0,
      rate: 0,
      total: dataToUse("other") || "-",
    },
  ];

  const TaxRows = [
    {
      title: "LESS TAX 1:",
      key: "tax1",
      valueOne: 0,
      rate: `${dataToUse("tax_percent")}%`,
      total: dataToUse("tax_amount") || "-",
    },
    {
      title: (
        <span>
          <span>LESS TAX2</span>{" "}
          <span className="text-gray-400 text-xs">(Net of Tax1):</span>
        </span>
      ),
      key: "tax2",
      valueOne: 0,
      rate: "0.00%", // Example from image
      total: dataToUse("tax2") || "-",
    },
    {
      title: (
        <span>
          <span>LESS OTHER</span>{" "}
          <span className="text-gray-400 text-xs">(hard input):</span>
        </span>
      ),
      key: "other_hard",
      valueOne: 0,
      rate: "0",
      total: dataToUse("other_hard") || "-",
    },
    {
      title: "LESS PLATINUM FEE:",
      key: "less_plat_fee",
      valueOne: 0,
      rate: "0",
      total: dataToUse("other_soft") || "-",
    },
    {
      title: "LESS MISC:",
      key: "less_misc_fee",
      valueOne: 0,
      rate: "0",
      total: dataToUse("other_soft") || "-",
    },
    {
      title: "MISC:",
      key: "less_misc_fee",
      valueOne: 0,
      rate: "0",
      total: dataToUse("other_soft") || "-",
    },
  ];
  const tableClassBinds = {
    className: "min-w-[600px]",
  };
  return (
    <div>
      {/* final figures */}
      <div className="border border-black mb-2">
        <Table
          showNoDataMessage={false}
          columns={columns}
          border={false}
          data={[]}
        />
        <TableRow
          columns={[
            { key: "name", accessor: "name", textAlign: "left" },
            { key: "value", accessor: "value", textAlign: "right" },
          ]}
          rowData={{
            name: "TXT GROSS REVENUE:",
            value: amountDisplay(dataToUse("tickets_gross")) || "0",
          }}
          rowContainerClass="bg-blue-200"
          inputClassName={settlementInputClassName}
        />
        <Table
          columns={columns}
          data={TxtGrossRows}
          hideHeaders
          border={false}
          onCellBlur={(rowIndex, accessor, value, row) => {
            handleBlur(rowIndex, accessor, value, row);
          }}
          enableAddRow={!isPDF}
          isPDF={isPDF}
          inputClassName={settlementInputClassName}
          deleteIcons={!isPDF}
        />
        <TableRow
          columns={[
            { key: "name", accessor: "name", textAlign: "left" },
            { key: "value", accessor: "value", textAlign: "right" },
          ]}
          rowData={{
            name: "TOTAL GROSS REVENUE:",
            value: amountDisplay(dataToUse("total_revenue")) || "0",
          }}
          rowContainerClass="bg-blue-200"
        />

        {/* Facility Fee Rows */}
        <Table
          columns={columns}
          data={FacilityFeeRows}
          hideHeaders
          border={false}
          enableAddRow={!isPDF}
          inputClassName={settlementInputClassName}
          isPDF={isPDF}
          deleteIcons={!isPDF}
        />
        <TableRow
          columns={[
            { key: "name", accessor: "name", textAlign: "left" },
            { key: "value", accessor: "value", textAlign: "right" },
          ]}
          rowData={{
            name: "ADJ NET GROSS:",
            value: amountDisplay(dataToUse("adjusted_gross")) || "0",
          }}
          rowContainerClass="bg-blue-200"
        />

        {/* Taxes */}
        <Table
          columns={columns}
          data={TaxRows}
          hideHeaders
          border={false}
          enableAddRow={!isPDF}
          isPDF={isPDF}
          deleteIcons={!isPDF}
        />
        <TableRow
          columns={[
            { key: "name", accessor: "name", textAlign: "left" },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
            },
          ]}
          rowData={{
            name: "NET GROSS RECEIPTS:",
            value: amountDisplay(dataToUse("net_income")) || "0",
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>
      {/* end final figures */}
    </div>
  );
}
