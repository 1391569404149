import React, { useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Select from "react-select";
import { Table, TableRow } from "./AppTable2";
import {
  createEventExpense,
  getExpenseGroups,
  getEventExpenses,
  updateEventExpense,
  deleteExpense,
} from "../queries/accounting";
import { amountDisplay, amountStrToInt } from "@/utils/money";
import { toast } from "react-toastify";
import { CheckIcon } from "@heroicons/react/24/outline";
import useBreakpoint from "@/hooks/useBreakpoint";

type Book = "offer" | "actual" | "artist" | "copro" | "final";

interface ExpenseTableProps {
  id: string;
  book?: Book;
  isPDF?: boolean;
  className?: string;
  title?: string;
  /* @shared represents shared expenses across each settlement */
  shared?: boolean;
  /* @unique represents unique expenses across each settlement */
  unique?: boolean;
}

const ExpenseTable: React.FC<ExpenseTableProps> = ({
  id,
  book = "offer",
  isPDF = false,
  className,
  title = "Expenses",
  shared = false,
  unique = false,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedSubGroup, setSelectedSubgroup] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [newExpenseData, setNewExpenseData] = useState({
    description: "",
    amount: "",
    paymentMethod: "",
    checkNumber: "",
    notes: "",
  });
  const queryClient = useQueryClient();

  const groupsQuery = useQuery(["expense-groups"], getExpenseGroups);
  const getMethod = () => {
    if (shared) return "shared";
    if (unique) return "unique";
    return "";
  };
  const expensesQuery = useQuery(
    ["event-expenses", id.toString(), book, getMethod()],
    () => getEventExpenses(id, book, getMethod())
  );

  const subGroups = () => {
    if (!groupsQuery.data?.length) return [];
    const parent = groupsQuery.data.find(
      (group) => group.name === selectedGroup?.label
    );
    if (!parent) return [];
    return parent.subgroups.map((subgroup) => ({
      label: subgroup.name,
      value: subgroup.name + ";id:" + subgroup.id,
    }));
  };

  const createExpenseMutation = useMutation({
    mutationFn: async (data: any) => createEventExpense(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id.toString()]);
      queryClient.invalidateQueries(["event-expenses", id.toString(), book]);
    },
  });

  const updateExpenseMutation = useMutation({
    mutationFn: async (data: any) => updateEventExpense(data),
    onSuccess: () => {
      console.log("{queryClient}", { queryClient });
      queryClient.invalidateQueries(["event-expenses", id.toString()]);
      queryClient.invalidateQueries(["event-expenses", id.toString(), book]);
    },
  });

  const deleteExpenseMutation = useMutation({
    mutationFn: (id) => {
      console.log("---> deleting expense");
      return deleteExpense(id);
    },
    onSuccess: async (id) => {
      queryClient.invalidateQueries(["event-detail", id.toString()]);
      queryClient.invalidateQueries(["variables-offer-pdf", id.toString()]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id.toString()]);
    },
  });

  const data = useMemo(() => {
    if (!expensesQuery.data) return [];

    return expensesQuery.data.map((expense) => {
      const total =
        Number(expense.mammoth || 0) +
        Number(expense.venue || 0) +
        Number(expense.artist || 0) +
        Number(expense.partner_1 || 0) +
        Number(expense.partner_2 || 0);
      const difference = total - Number(expense.amount);
      return {
        id: expense.id,
        description: expense.description || "UNKNOWN",
        amount: expense.amount,
        paymentMethod: expense.payment_method,
        checkNumber: expense.check_number,
        notes: expense.notes,
        subgroup: expense.subgroup,
        difference,
        total,
        ...expense,
      };
    });
  }, [expensesQuery.data]);

  const exclude_artist = book !== "artist";
  const exclude_copro = book !== "copro";
  const exclude_final = book !== "final";
  const handleFieldUpdate = (field: string, v: any, expenseId: number) => {
    console.log("{field, v, expenseId}", { field, v, expenseId });
    const expenseToUpdate = data.find((expense) => expense.id === expenseId);
    if (!expenseToUpdate) return;
    const value = v;
    const isString = isNaN(Number(v));
    if (!isString) {
      // value = amountStrToInt(v);
    }
    console.log({
      mut: {
        ...expenseToUpdate,
        expense_id: expenseId,
        payment_method:
          field === "paymentMethod" ? value : expenseToUpdate.paymentMethod,
        check_number:
          field === "checkNumber" ? value : expenseToUpdate.checkNumber,
        description:
          field === "description" ? value : expenseToUpdate.description,
        amount: field === "amount" ? value : expenseToUpdate.amount,
        notes: field === "notes" ? value : expenseToUpdate.notes,
        [field]: value,
        exclude_artist,
        exclude_copro,
        exclude_final,
      },
    });
    updateExpenseMutation.mutate({
      ...expenseToUpdate,
      expense_id: expenseId,
      payment_method:
        field === "paymentMethod" ? value : expenseToUpdate.paymentMethod,
      check_number:
        field === "checkNumber" ? value : expenseToUpdate.checkNumber,
      description:
        field === "description" ? value : expenseToUpdate.description,
      amount: field === "amount" ? value : expenseToUpdate.amount,
      notes: field === "notes" ? value : expenseToUpdate.notes,
      exclude_artist,
      exclude_copro,
      exclude_final,
      [field]: value,
    });
  };

  const handleResetNewExpenseForm = () => {
    setNewExpenseData({
      description: "",
      amount: "",
      paymentMethod: "",
      checkNumber: "",
      notes: "",
    });
    setSelectedGroup(null);
    setSelectedSubgroup(null);
  };

  const bundleAllExpenses = expensesQuery.data?.reduce(
    (acc, curr) => {
      acc.mammoth += curr.mammoth || 0;
      acc.venue += curr.venue || 0;
      acc.artist += curr.artist || 0;
      acc.partner_1 += curr.partner_1 || 0;
      acc.partner_2 += curr.partner_2 || 0;
      acc.totals +=
        (curr.mammoth || 0) +
        (curr.venue || 0) +
        (curr.artist || 0) +
        (curr.partner_1 || 0) +
        (curr.partner_2 || 0);
      acc.offer += curr.amount || 0;
      return acc;
    },
    {
      mammoth: 0,
      venue: 0,
      artist: 0,
      partner_1: 0,
      partner_2: 0,
      totals: 0,
      offer: 0,
      difference: 0,
    }
  );
  const TotalRow = {
    render: () => (
      <>
        <TableRow
          rowData={{
            description: "TOTALS",
            notes: "",
            mammoth: amountDisplay(bundleAllExpenses?.mammoth),
            venue: amountDisplay(bundleAllExpenses?.venue),
            artist: amountDisplay(bundleAllExpenses?.artist),
            partner_1: amountDisplay(bundleAllExpenses?.partner_1),
            partner_2: amountDisplay(bundleAllExpenses?.partner_2),
            totals: amountDisplay(bundleAllExpenses?.totals),
            amount: amountDisplay(bundleAllExpenses?.offer),
            difference: amountDisplay(
              bundleAllExpenses?.totals - bundleAllExpenses?.offer
            ),
          }}
          rowContainerClass="bg-blue-200"
          columns={[
            {
              title: "EXPENSE",
              accessor: "description",
              textAlign: "left",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("description", value, data[rowIndex].id),
            },
            {
              title: "NOTES",
              accessor: "notes",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "MAMMOTH",
              accessor: "mammoth",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "VENUE",
              accessor: "venue",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "ARTIST",
              accessor: "artist",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "PARTNER 1",
              accessor: "partner_1",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("notes", value, data[rowIndex].id),
            },
            {
              title: "PARTNER 2",
              accessor: "partner_2",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("partner_2", value, data[rowIndex].id),
            },
            {
              title: "TOTALS",
              accessor: "totals",
              textAlign: "center",
              editable: false,
            },
            {
              title: "OFFER",
              accessor: "amount",
              textAlign: "center",
              editable: false,
              onBlur: (value: any, rowIndex: number) =>
                handleFieldUpdate("amount", value, data[rowIndex].id),
            },
            {
              title: "DIFFERENCE",
              accessor: "difference",
              textAlign: "center",
              editable: false,
            },
          ]}
        />
      </>
    ),
  };
  const breakpoint = useBreakpoint();
  const customRows = () => {
    const rows = [TotalRow];
    return rows;
  };
  return (
    <div className={`w-full   mt-6${className ? ` ${className}` : ""}`}>
      <Table
        customRows={customRows()}
        addRowButtonTitle="Add Expense"
        className="min-w-[1350px]"
        enableAddRow
        onReorder={() => {
          console.log("reordered");
        }}
        orderable
        onDelete={(index, row) => {
          if (
            window.confirm("Are you sure you want to delete this expense?") ===
            true
          ) {
            deleteExpenseMutation.mutate(row.id);
            toast.success("Expense deleted");
          }
        }}
        tableTitle={title}
        inputClassName="bg-input-blue hover:bg-orange-400 duration-200 py-1"
        onAddRow={(data) => {
          const subgroup = selectedSubGroup?.value.split(";id:")[1];
          if (!subgroup) {
            toast.error("Please select a subgroup");
            return;
          }
          createExpenseMutation.mutate({
            subgroup,
            payment_method: newExpenseData.paymentMethod,
            check_number: newExpenseData.checkNumber,
            description: selectedSubGroup?.label,
            ...data,
            is_offer: false,
            exclude_artist,
            exclude_copro,
            exclude_final,
          });
          toast.success("Expense added");
          handleResetNewExpenseForm();
        }}
        columns={[
          {
            title: (
              <span className="text-red-400 text-xs">MAMMOTH PAID BY</span>
            ),
            addRender: () => <span></span>,
            accessor: "paid_by",
            textAlign: "left",
            editable: false,
            width: "85px",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("description", value, data[rowIndex].id),
          },
          {
            title: "EXPENSE",
            accessor: "subgroup_name",
            textAlign: "left",
            editable: false,
            className: "relative",
            width: breakpoint.isGreaterOrEqual("lg") ? "165px" : "150px",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("description", value, data[rowIndex].id),
            addRender: (props) => {
              console.log("props", props);
              return (
                <div>
                  <div className="bg-input-blue p-2">
                    <Select
                      options={subGroups()}
                      value={selectedSubGroup}
                      onChange={(value) => setSelectedSubgroup(value)}
                      placeholder="Subgroup"
                      isDisabled={
                        groupsQuery.isLoading ||
                        groupsQuery.isError ||
                        !selectedGroup
                      }
                      className=" text-sm h-full"
                    />
                  </div>
                  <Select
                    options={groupsQuery.data?.map((group) => ({
                      label: group.name,
                      value:
                        group.name.toLowerCase().replace(/\s+/g, "-") +
                        ";id:" +
                        group.id,
                    }))}
                    value={selectedGroup}
                    onChange={(value) => setSelectedGroup(value)}
                    placeholder="Expense Group"
                    isDisabled={groupsQuery.isLoading || groupsQuery.isError}
                    className="w-44"
                  />
                </div>
              );
            },
          },
          {
            title: "NOTES",
            accessor: "notes",
            textAlign: "center",
            editable: true,
            addRowPlaceholder: "Notes",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("notes", value, data[rowIndex].id),
          },
          {
            title: "MAMMOTH",
            accessor: "mammoth",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("mammoth", value, data[rowIndex].id),
          },
          {
            title: "VENUE",
            accessor: "venue",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("venue", value, data[rowIndex].id),
          },
          {
            title: "ARTIST",
            accessor: "artist",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number, colKey: string) =>
              handleFieldUpdate("artist", value, data[rowIndex].id),
          },
          {
            title: "PARTNER 1",
            accessor: "partner_1",
            textAlign: "center",
            editable: true,
            format: "money",
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("partner_1", value, data[rowIndex].id),
          },
          {
            title: "PARTNER 2",
            accessor: "partner_2",
            textAlign: "center",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("partner_2", value, data[rowIndex].id),
            format: "money",
          },
          {
            title: "TOTALS",
            accessor: "total",
            textAlign: "center",
            editable: false,
            format: "money",
            addRender: () => <span>N/A</span>,
          },
          {
            title: "OFFER",
            accessor: "amount",
            textAlign: "center",
            editable: true,
            onBlur: (value: any, rowIndex: number) =>
              handleFieldUpdate("amount", value, data[rowIndex].id),
            format: "money",
          },
          {
            title: "DIFFERENCE",
            accessor: "difference",
            textAlign: "center",
            editable: false,
            format: "money",
            addRender: () => <span>N/A</span>,
          },
        ]}
        data={data}
        hideHeaders={false}
      />
    </div>
  );
};

export default ExpenseTable;
