import React from "react";
import { useNavigate } from "react-router-dom";

export const NextEventCard = ({
  url,
  eventName,
  venue,
  date,
  backgroundImage,
}) => {
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${backgroundImage}')`,
    border: `1px solid #4a4a4a`,
  };

  const handleCardClick = () => {
    url && navigate(url);
  };

  return (
    <div
      onClick={handleCardClick}
      className="cursor-pointer border-none box-border text-cave-white h-[400px] w-full bg-cover bg-center flex flex-col flex-end p-8 gap-2 rounded-xl"
      style={backgroundStyle}
    >
      {/* <img src={"/static/sexxy-red.png"} alt="Event Background" className="w-full h-full object-cover" /> */}
      <p className="text-sm uppercase tracking-widest mt-48">Next Event</p>
      <h1 className="text-3xl font-sans font-bold">{eventName}</h1>
      <p className="text-xl">{venue}</p>
      <p className="text-lg">{date}</p>
    </div>
  );
};
