import React, { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import * as yup from "yup";
import {
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  Toggle,
} from "@/components/Form";
import { createEventExpense } from "@/queries/accounting";
import { formatDollarsToCents } from "@/utils/money";
import Button from "@/components/Button";

const PaymentOptions = [
  { value: "check", label: "Check" },
  { value: "cash", label: "Cash" },
  { value: "cc", label: "Credit Card" },
  { value: "office", label: "Office" },
  { value: "wire", label: "Wire" },
  { value: "ach", label: "ACH" },
  { value: "internal", label: "Internal" },
  { value: "building", label: "Building" },
];

const AddExpenseForm = ({
  eventId,
  subgroup,
  copros = [],
  onSuccess,
  onCancel,
  books = "actual",
}) => {
  const queryClient = useQueryClient();

  const addSchema = yup.object().shape({
    payment_method: yup.object().shape({
      value: yup.string().required("Payment method is required"),
    }),
    description: yup.string().required("Description is required"),
    amount: yup.string().required("Amount is required"),
    check_number: yup.string().when(["payment_method"], {
      is: (payment_method) => payment_method?.value === "check",
      then: yup
        .string()
        .required("Check number is required for check payments"),
    }),
  });

  //    event_id: int
  //    subgroup: int
  //    payment_method: str
  //    check_number: str | None = None  # Make check_number optional
  //    description: str
  //    amount: str | None = None  # Make amount optional and validate it
  //    notes: str | None = None  # Make notes optional
  //    is_offer: bool = False
  //    exclude_artist: bool = False
  //    exclude_copro: bool = False
  //    exclude_final: bool = False
  //          subgroup: data.subgroup.value,
  //          payment_method: data.payment_method.value,
  //          check_number:
  //            data.payment_method.value === "check" ? data.check_number : "",
  //          description: data.description,
  //          amount: formatDollarsToCents(data.amount),
  //          notes: data.notes || "",
  //          is_offer: books === "offer",
  //          exclude_artist: data.exclude_artist || false,
  //          exclude_copro: data.exclude_copro || false,
  //          exclude_final: data.exclude_final || false,

  const mutation = useMutation({
    mutationFn: (data) => {
      // Format to exactly match ExpenseCreateSchema fields
      const formattedData = {
        event_id: parseInt(eventId), // Make sure this is a number
        subgroup: subgroup.id, // Should be a number
        payment_method: data.payment_method.value,
        check_number:
          data.payment_method.value === "check" ? data.check_number : null,
        description: data.description,
        amount: data.amount.toString(), // Schema expects a string
        notes: data.notes || null,
        is_offer: books === "offer",
        exclude_artist: data.exclude_artist || false,
        exclude_copro: data.exclude_copro || false,
        exclude_final: data.exclude_final || false,
      };

      return createEventExpense(eventId, formattedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["expenses"]);
      onSuccess?.();
    },
    onError: (error) => {
      console.error("Failed to add expense:", error);
    },
  });
  const CheckNumberInput = () => {
    const { watch } = useFormContext();
    const paymentMethod = watch("payment_method");

    if (paymentMethod?.value !== "check") {
      return null;
    }

    return (
      <Input
        label="Check Number"
        name="check_number"
        placeholder="Enter check number"
        required
      />
    );
  };

  const paymentOptions = [
    ...PaymentOptions,
    ...copros.map((copro) => ({
      value: copro.id,
      label: `Copro - ${copro.name}`,
    })),
  ];

  const defaultValues = {
    payment_method: { value: "office", label: "Office" },
    description: "",
    amount: "",
    notes: "",
    exclude_artist: false,
    exclude_copro: false,
    exclude_final: false,
  };

  return (
    <div className="mt-4 mb-4 p-6 border-2 border-blue-300 bg-cave-blue3">
      <h3 className="text-xl font-bold mb-4 text-cave-black dark:text-cave-white">
        Add Expense to {subgroup.name}
      </h3>

      <FormBuilder
        defaultValues={defaultValues}
        onSubmit={mutation.mutate}
        schema={addSchema}
      >
        <FormSelect
          name="payment_method"
          label="Payment Method"
          options={paymentOptions}
          required
        />

        <CheckNumberInput />

        <Input
          label="Description"
          name="description"
          placeholder="Short description of the expense"
          required
        />

        <Input label="Amount" name="amount" placeholder="$0.00" required />

        <TextInput
          label="Notes"
          name="notes"
          placeholder="Optional notes"
          rows={3}
        />

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="p-2 flex justify-between w-full text-left font-semibold bg-cave-blue3 hover:bg-cave-blue2">
                Exclusions
                <ChevronRightIcon
                  className={open ? "rotate-90 transform w-6 h-6" : "w-6 h-6"}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="text-cave-black dark:text-cave-white py-2">
                <div className="my-2">
                  <Toggle label="Exclude From Artist" name="exclude_artist" />
                </div>
                <div className="my-2">
                  <Toggle label="Exclude From CoPro" name="exclude_copro" />
                </div>
                <div className="my-2">
                  <Toggle label="Exclude From Final" name="exclude_final" />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="flex justify-end gap-4 mt-6 dark:text-cave-black">
          <Button type="button" onClick={onCancel} variant="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={mutation.isLoading} variant="primary">
            {mutation.isLoading ? "Adding..." : "Add Expense"}
          </Button>
        </div>
      </FormBuilder>
    </div>
  );
};

export default AddExpenseForm;
