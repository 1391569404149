import { toast } from "react-toastify";

export const handleValidationError = (
  error,
  setValidationErrors,
  fieldPrefix = ""
) => {
  if (error.inner) {
    const errors = {};
    error.inner.forEach((err) => {
      errors[err.path] = err.message;
    });
    setValidationErrors(errors);

    // Show toast for first error
    if (error.inner[0]) {
      toast.error(error.inner[0].message);
    }
  } else {
    setValidationErrors({ submit: error.message || "Validation failed" });
    toast.error(error.message || "Validation failed");
  }
};

export const handleSubmissionError = (error, setValidationErrors) => {
  console.error("Submission error:", error);
  const errorMessage = error.message || "Failed to submit advance";
  setValidationErrors((prev) => ({ ...prev, submit: errorMessage }));
  toast.error(errorMessage);
};

export const clearValidationErrors = (setValidationErrors) => {
  setValidationErrors({});
};
