import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { useFormContext } from "react-hook-form";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Toggle = (props) => {
  const { name, value, label, description } = props;
  const [enabled, setEnabled] = useState(false);

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (value) {
      setEnabled(value);
      setValue(name, value);
    }
  }, [value]);

  const toggleValue = (value) => {
    console.log("Getting called", value);
    setEnabled(value);
    setValue(name, value);
  };

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label
          as="span"
          className="text-sm font-medium leading-6 text-cave-black dark:text-cave-white"
          passive
        >
          {label}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {description}
        </Switch.Description>
      </span>
      <Switch
        checked={enabled}
        onChange={toggleValue}
        className={classNames(
          enabled ? "bg-blue-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-cave-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <input {...register(name)} type="hidden" />
    </Switch.Group>
  );
};

export default Toggle;
