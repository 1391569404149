import React, { useEffect, useState } from "react";
import MoneyInputComponent from "../Common/MoneyInput";

interface SettlementInputFieldProps {
  value: string | number; // Controlled value from parent
  defaultValue?: string | number; // Default value if value is not provided
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: string | number) => void; // Sends updated value to parent
  className?: string;
  editable?: boolean;
  format?: "text" | "money";
  placeholder?: string;
  isPDF?: boolean;
}

const SettlementInputField: React.FC<SettlementInputFieldProps> = ({
  value,
  defaultValue,
  onBlur,
  onChange,
  className = "",
  editable = true,
  format = "text",
  placeholder = "",
  isPDF = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (defaultValue) return; // Do not update value if defaultValue is provided
    if (onChange) onChange(newValue); // Notify parent about changes
  };

  return format === "money" ? (
    <MoneyInputComponent
      value={value}
      placeholder={placeholder}
      onChange={(e) => handleChange(e)} // Handle changes for MoneyInputComponent
      onBlur={onBlur}
      className={`w-full block focus:outline-none ${
        editable && !isPDF
          ? "bg-input-blue hover:bg-orange-400 duration-200"
          : ""
      } focus:ring text-base self-start ${className}`}
      editable={editable && !isPDF}
    />
  ) : (
    <input
      defaultValue={defaultValue}
      value={value} // Controlled input
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={onBlur}
      type="text"
      className={`w-full block focus:outline-none ${
        editable && !isPDF
          ? "bg-input-blue hover:bg-orange-400 duration-200"
          : ""
      } focus:ring text-base self-start ${className}`}
      readOnly={!editable || isPDF} // Disable editing if not editable or in PDF mode
    />
  );
};

export default SettlementInputField;
