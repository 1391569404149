import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { getEventPDFs, createEventPDF } from "@/queries/events";
import { CommonSettlementProps, SettlementTab } from "@/types/settlement";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
} from "react";
import { toast } from "react-toastify";

const ThreeDotSpinner = () => (
  <div className="flex space-x-1">
    <div className="w-2 h-2 bg-white rounded-full animate-bounce"></div>
    <div
      className="w-2 h-2 bg-white rounded-full animate-bounce"
      style={{ animationDelay: "0.2s" }}
    ></div>
    <div
      className="w-2 h-2 bg-white rounded-full animate-bounce"
      style={{ animationDelay: "0.4s" }}
    ></div>
  </div>
);

interface PDFInfo {
  id: number;
  url: string;
  timestamp: number;
  file_type: string;
}

export default function SettlementTabs() {
  const { event, pageIndex, setPageIndex } = useSettlementComponent();
  const queryClient = useQueryClient();

  const [pdfInfo, setPdfInfo] = useState<Record<string, PDFInfo>>({});
  const [generatingPDFId, setGeneratingPDFId] = useState<number | null>(null);
  const id = event?.data?.id ?? "";

  const pdfFileType = useCallback(() => `${pageIndex}_settlement`, [pageIndex]);

  const { data: pdfData, refetch: refetchPDFs } = useQuery(
    ["event-pdfs", id],
    () => getEventPDFs(id),
    {
      refetchInterval: generatingPDFId ? 5000 : false,
      onSuccess: (data) => {
        if (!Array.isArray(data) || data.length === 0) {
          console.error("Received data is not a non-empty array:", data);
          setGeneratingPDFId(null);
          return;
        }

        const newPdfInfo: Record<string, PDFInfo> = {};
        let generatedPDFFound = false;

        data.forEach((pdf) => {
          const type = pdf.file_type.replace("_settlement", "");
          if (!newPdfInfo[type] || pdf.id > newPdfInfo[type].id) {
            newPdfInfo[type] = {
              id: pdf.id,
              url: pdf.file_url,
              timestamp: new Date(pdf.created).getTime(),
              file_type: pdf.file_type,
            };
          }

          if (pdf.id === generatingPDFId && pdf.file_url) {
            generatedPDFFound = true;
          }
        });

        setPdfInfo(newPdfInfo);

        if (generatedPDFFound) {
          setGeneratingPDFId(null);
        }
      },
      onError: (error) => {
        console.error("Error fetching PDFs:", error);
        setGeneratingPDFId(null);
      },
    }
  );

  useEffect(() => {
    refetchPDFs();
  }, [id, refetchPDFs]);

  const createPDFMutation = useMutation(
    (data: { name: string; file_type: string; note: string }) =>
      createEventPDF(id, data),
    {
      onError: (error) => {
        console.error("Error starting PDF generation:", error);
        toast.error("Error starting PDF generation");
        setGeneratingPDFId(null);
      },
      onSuccess: (data) => {
        setGeneratingPDFId(data.id);
        queryClient.invalidateQueries(["event-pdfs", id]);

        // Fallback timeout
        setTimeout(() => {
          setGeneratingPDFId((current) => {
            if (current === data.id) {
              refetchPDFs();
              toast.warn(
                "PDF generation is taking longer than expected. Checking for updates..."
              );
              return null;
            }
            return current;
          });
        }, 300000); // 5 minutes timeout
      },
    }
  );

  const handleCreatePDF = () => {
    const data = {
      name: pdfFileType(),
      file_type: pdfFileType(),
      note: "",
    };

    createPDFMutation.mutate(data);
  };

  const handleViewPDF = useCallback(() => {
    const currentPdfInfo = pdfInfo[pageIndex];
    if (currentPdfInfo && currentPdfInfo.url) {
      window.open(currentPdfInfo.url, "_blank", "noopener,noreferrer");
    } else if (generatingPDFId !== null) {
      toast.info("PDF is still generating. Please wait.");
    } else {
      toast.error("No completed PDF available for this tab");
    }
  }, [pdfInfo, pageIndex, generatingPDFId]);

  const getPDFButtonText = () => {
    if (
      generatingPDFId !== null &&
      pdfInfo[pageIndex]?.id === generatingPDFId
    ) {
      return (
        <span className="flex items-center gap-2">
          Generating... &nbsp;
          <ThreeDotSpinner />
        </span>
      );
    } else if (pdfInfo[pageIndex]) {
      return "Regenerate PDF";
    } else {
      return "Create PDF";
    }
  };

  return (
    <div className="w-full fixed bottom-0 bg-white lg:bg-white text-lg flex lg:flex-nowrap flex-wrap gap-3 py-1.5 z-[10]">
      <button
        className={`${
          pageIndex === "artist"
            ? "bg-blue-600 text-cave-white"
            : "hover:bg-blue-400 duration-200"
        } rounded-md p-2`}
        onClick={() => setPageIndex("artist")}
      >
        ARTIST
      </button>
      <button
        className={`${
          pageIndex === "copro"
            ? "bg-blue-600 text-cave-white"
            : "hover:bg-blue-400 duration-200"
        } rounded-md p-2`}
        onClick={() => setPageIndex("copro")}
      >
        COPRO
      </button>
      <button
        className={`${
          pageIndex === "final"
            ? "bg-blue-600 text-cave-white"
            : "hover:bg-blue-400 duration-200"
        } rounded-md p-2`}
        onClick={() => setPageIndex("final")}
      >
        FINAL
      </button>
      <button
        onClick={handleCreatePDF}
        disabled={
          generatingPDFId !== null && pdfInfo[pageIndex]?.id === generatingPDFId
        }
        className={`px-4 py-2 rounded-md font-semibold text-cave-white transition-colors duration-200 w-full lg:w-auto
          ${
            generatingPDFId !== null &&
            pdfInfo[pageIndex]?.id === generatingPDFId
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 active:bg-blue-700"
          }`}
      >
        {getPDFButtonText()}
      </button>
      <button
        onClick={handleViewPDF}
        disabled={!pdfInfo[pageIndex] || !pdfInfo[pageIndex].url}
        className={`px-4 py-2 rounded-md font-semibold text-cave-white transition-colors duration-200 w-full lg:w-auto
          ${
            !pdfInfo[pageIndex] || !pdfInfo[pageIndex].url
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-600 active:bg-green-700"
          }`}
      >
        View PDF
      </button>
    </div>
  );
}
