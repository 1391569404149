import React, { useCallback, useState } from "react";
import { Modal } from "flowbite-react";
import {
  FormBuilder,
  Input,
  TextInput,
  FormMultiSelect,
} from "@/components/Form";
import Button from "@/components/Button";
import { useQuery } from "@tanstack/react-query";
import { getVenues } from "@/queries/venues";
import { getArtists } from "@/queries/artists";
import * as yup from "yup";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { toast } from "react-toastify";

dayjs.extend(isBetween);

const getDatesInRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = dayjs(startDate);
  // Subtract 1 day from endDate to handle FullCalendar's exclusive end date
  const lastDate = dayjs(endDate).subtract(1, "day");

  while (
    currentDate.isBefore(lastDate) ||
    currentDate.isSame(lastDate, "day")
  ) {
    dates.push(currentDate.toDate());
    currentDate = currentDate.add(1, "day");
  }

  return dates;
};

const HoldModal = ({
  showModal,
  setShowModal,
  selectedDates,
  onSubmit,
  existingHolds,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: venues, isLoading: isLoadingVenues } = useQuery(
    ["venues"],
    getVenues
  );
  const { data: artists, isLoading: isLoadingArtists } = useQuery(
    ["artists"],
    getArtists
  );

  const venueOptions =
    venues?.map((venue) => ({ value: venue.id, label: venue.name })) || [];
  const artistOptions =
    artists?.map((artist) => ({ value: artist.id, label: artist.name })) || [];

  const addSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string(),
    venues: yup
      .array()
      .of(yup.object())
      .min(1, "At least one venue is required"),
    artists: yup.array().of(yup.object()),
  });

  const formatDate = useCallback((date) => {
    return date ? dayjs(date).format("YYYY-MM-DD") : "";
  }, []);

  const getDateDisplay = useCallback(() => {
    if (!selectedDates) return "";
    if (selectedDates.date) {
      return ` on ${formatDate(selectedDates.date)}`;
    } else if (selectedDates.start && selectedDates.end) {
      const startDate = formatDate(selectedDates.start);
      const endDate = formatDate(selectedDates.end);
      return startDate === endDate
        ? `On ${startDate}`
        : ` from ${startDate} to ${endDate}`;
    }
    return "";
  }, [selectedDates, formatDate]);

  const handleSubmit = useCallback(
    async (values) => {
      setIsSubmitting(true);
      try {
        // Get all dates in the range
        const dates = selectedDates?.date
          ? [formatDate(selectedDates.date)]
          : getDatesInRange(selectedDates?.start, selectedDates?.end).map(
              (date) => formatDate(date)
            );

        const payload = {
          name: values.name,
          description: values.description || "",
          venue_ids: Array.isArray(values.venues)
            ? values.venues.map((v) => v?.value).filter(Boolean)
            : values.venues?.value
            ? [values.venues.value]
            : [],
          artist_ids: Array.isArray(values.artists)
            ? values.artists.map((a) => a?.value).filter(Boolean)
            : values.artists?.value
            ? [values.artists.value]
            : [],
          dates: dates,
        };
        console.log("Submitting payload:", payload);
        if (!payload.name) {
          throw new Error("Name is required");
        }
        if (payload.venue_ids.length === 0) {
          throw new Error("At least one venue is required");
        }
        if (payload.dates.length === 0) {
          throw new Error("At least one date is required");
        }
        await onSubmit(payload);
        setShowModal(false);
      } catch (error) {
        console.error("Error submitting form:", error);
        alert(error.message || "An error occurred while creating the hold");
      } finally {
        setIsSubmitting(false);
      }
    },
    [onSubmit, selectedDates, formatDate, setShowModal]
  );

  if (isLoadingVenues || isLoadingArtists) {
    return <div>Loading...</div>;
  }

  if (!venueOptions.length || !artistOptions.length) {
    console.error("Venue or artist options are not loaded yet");
    return null;
  }

  const renderDatePreview = () => {
    const dates = selectedDates?.date
      ? [selectedDates.date]
      : getDatesInRange(selectedDates?.start, selectedDates?.end);

    return (
      <div className="mb-4 p-3 bg-gray-50 rounded-lg">
        <h4 className="text-sm font-semibold mb-2">Selected Dates:</h4>
        <div className="flex flex-wrap gap-2 max-h-40 overflow-y-auto">
          {dates.map((date) => {
            const formattedDate = dayjs(date).format("YYYY-MM-DD");
            const holdsForDate =
              existingHolds?.filter((hold) => hold.date === formattedDate) ||
              [];

            return (
              <div
                key={date.toString()}
                className="px-3 py-1 bg-white rounded border text-sm"
              >
                {dayjs(date).format("MMM D, YYYY")}
                {holdsForDate.length > 0 && (
                  <span className="ml-2 px-2 py-0.5 bg-yellow-100 text-yellow-800 rounded-full text-xs">
                    {holdsForDate.length} hold(s)
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal
      dismissible
      show={showModal}
      onClose={() => setShowModal(false)}
      className="max-w-2xl"
    >
      <Modal.Header>
        Create Hold
        {getDateDisplay()}
      </Modal.Header>
      <FormBuilder onSubmit={handleSubmit} schema={addSchema}>
        <Modal.Body className="space-y-4">
          {renderDatePreview()}
          <Input name="name" label="Name" autoFocus />
          <FormMultiSelect
            name="venues"
            label="Venues"
            options={venueOptions}
          />
          <FormMultiSelect
            name="artists"
            label="Artists"
            options={artistOptions}
          />
          <TextInput name="description" label="Description" />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Creating..." : "Create Hold(s)"}
          </Button>
          <Button
            color="gray"
            onClick={() => setShowModal(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </FormBuilder>
    </Modal>
  );
};

export default HoldModal;
