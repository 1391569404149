import React, { useEffect } from "react";
import Select from "react-select";
import { useFormContext, useController } from "react-hook-form";

export const FormSelect = (props) => {
  const { name, label, options, helpText, required, defaultValue } = props;
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext();

  const errorMessage = errors[props.name]?.message?.toString();
  const {
    field: { value, onChange, onBlur, ref, ...restSelField },
  } = useController({ name: name, control: control });

  // Set default value on component mount
  useEffect(() => {
    if (defaultValue && !value) {
      // Find the full option object that matches the default value
      const defaultOption = options.find(
        (option) => option.value === defaultValue
      );
      if (defaultOption) {
        setValue(name, defaultOption);
      }
    }
  }, [defaultValue, setValue, name, options, value]);

  return (
    <div className="my-3">
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium text-cave-black dark:text-cave-black"
      >
        {label}
      </label>
      <Select
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        required={required}
        {...restSelField}
      />
      {errorMessage && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
          {errorMessage}
        </p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {helpText}
        </p>
      )}
    </div>
  );
};

export default FormSelect;
