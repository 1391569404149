import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
} from "./base";

export const getImages = async (entityType, id, marketingOnly = false) => {
  const url = `/api/${entityType}/images/${id}/${
    marketingOnly ? "?marketing_only=true" : ""
  }`;
  return await makeRequest(url, "GET", await jsonHeaders());
};

export const createImage = async (entityType, parentId, data) => {
  const url = `/api/${entityType}/images/${parentId}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type"); // Let browser set correct multipart boundary
  return makeRequest(url, "POST", headers, data);
};

export const deleteImage = async (entityType, imageId) => {
  console.log(`delete${entityType}Image called with imageId:`, imageId);
  const url = `/api/${entityType}/images/delete/${imageId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  try {
    const resp = await makeRequest(url, "DELETE", headers);
    if (!resp.ok) {
      throw new Error(`Delete failed with status ${resp.status}`);
    }
    const jsonResponse = await resp.json();
    console.log(`delete${entityType}Image response:`, jsonResponse);
    if (!jsonResponse.success) {
      throw new Error("Delete operation failed");
    }
    return jsonResponse;
  } catch (error) {
    console.error(`Error in delete${entityType}Image:`, error);
    if (error instanceof SyntaxError) {
      console.error(
        "Response was not valid JSON. Full response:",
        await error.response.text()
      );
    }
    throw error;
  }
};
