import { getApiRequest, makeRequest, jsonHeaders } from "./base";

export const getEventAdvance = async (eventId) => {
  const response = await getApiRequest(`/api/adv/${eventId}/`);
  if (!response) {
    return null;
  }
  return response.data;
};

export const createEventAdvance = async (eventId, data) => {
  const headers = await jsonHeaders(true);
  const body = JSON.stringify(data);
  const response = await makeRequest(
    `/api/adv/${eventId}/`,
    "POST",
    headers,
    body
  );
  if (!response.ok) {
    throw new Error(`Failed to create advance: ${response.statusText}`);
  }
  const result = await response.json();
  if (!result.success) {
    throw new Error(result.message || "Operation failed");
  }
  return result.data;
};

export const updateEventAdvance = async (eventId, data) => {
  try {
    const headers = await jsonHeaders(true);
    const body = JSON.stringify(data);
    const response = await makeRequest(
      `/api/adv/${eventId}/`,
      "PUT",
      headers,
      body
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || `Failed to update advance: ${response.statusText}`
      );
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || "Operation failed");
    }

    return result.data;
  } catch (error) {
    console.error("Error updating advance:", error);
    throw error;
  }
};

export const deleteEventAdvance = async (eventId) => {
  const headers = await jsonHeaders(true);
  return await makeRequest(`/api/adv/${eventId}/`, "DELETE", headers);
};
