import { DarkToggle } from "./DarkToggle";
import { Logo } from "./Logo";
import { Notifications } from "./Notifications";
import { SearchBar } from "./SearchBar";
import { UserMenu } from "./UserMenu";
import { ScrollToTop } from "@/components/ScrollToTop";

export const TopHeader = ({ openModal, toggleMobileMenu }) => {
  return (
    <>
      <nav className="bg-cave-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-[#181818]">
        <div className="flex justify-between items-center mb-6">
          <div className="flex justify-start items-center">
            <Logo />
          </div>
          <div className="flex justify-between items-center lg:order-2">
            {/* <Notifications />

            <span className="hidden mx-2 w-px h-5 bg-gray-200 dark:bg-gray-600 lg:inline"></span>
            */}
            <DarkToggle />

            <span className="hidden mx-2 w-px h-5 bg-gray-200 dark:bg-gray-600 md:inline"></span>

            <UserMenu />

            <button
              type="button"
              id="toggleMobileMenuButton"
              data-collapse-toggle="toggleMobileMenu"
              className="items-center p-2 text-gray-500 rounded-lg md:ml-2 lg:hidden dark:text-gray-400 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              onClick={() => toggleMobileMenu()}
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="mb-2">
          <SearchBar onClick={openModal} />
        </div>
      </nav>
      <ScrollToTop />
    </>
  );
};
