import React, { useState } from "react";
import { FormBuilder, Input } from "./Form";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Logo } from "@/components/Header/TopHeader/Logo";
import { passwordReset } from "@/queries/auth";

const PasswordReset = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [running, setRunning] = useState(false);

  const resetMutation = useMutation({
    mutationFn: (data) => {
      return passwordReset(data);
    },
    onMutate: (variables) => {
      setRunning(true);
    },
    onSuccess: (data, variables, context) => {
      setError(false);
      setRunning(false);
      setSuccess(true);
    },
    onError: (error, variables, context) => {
      setError(error.data.message);
      setRunning(false);
    },
  });

  const onSubmit = (data) => {
    setError(false);
    resetMutation.mutate(data);
  };

  const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  let headerText = "Reset Your Password";
  if (success) {
    headerText = "Password Reset Email Sent";
  }

  return (
    <section className="bg-gray-300 dark:bg-[#181818]">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="mb-6">
          <Logo />
        </div>
        <div className="w-full bg-cave-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-cave-black md:text-2xl dark:text-cave-white">
              {headerText}
            </h1>
            {error && <p className="text-sm text-red-600">{error}</p>}
            {!success && (
              <FormBuilder onSubmit={onSubmit} schema={loginSchema}>
                <Input
                  name="email"
                  type="email"
                  placeholder="name@company.com"
                  label="Your email"
                  autoFocus
                />
                <button
                  type="submit"
                  disabled={running}
                  className="mt-2 w-full shadow text-cave-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {running ? "Submitting" : "Reset Password"}
                </button>
              </FormBuilder>
            )}
            {success && (
              <p className="my-4">
                If we have record of an account with this email address you will
                receive an email with instructions for how to reset your account
                password.
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordReset;
