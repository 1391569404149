import {
  createEventExpense,
  deleteExpense,
  getArtistEventRollups,
  getEventExpenses,
  updateEventExpense,
} from "@/queries/accounting";
import {
  getEvent,
  updateEvent,
  getEventSettlementInfo,
  getEventSettlement,
  getEventPeople,
  updateEventSettlement,
  getEventOfferVariables,
} from "@/queries/events";
import { EventSettlementOverride, CaveEvent, Rollup } from "@/types/event";
import { Expense } from "@/types/expense";
import { SettlementTab } from "@/types/settlement";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
interface UpdateEventSettlementParams {
  id?: string | number;
  book: "artist" | "copro" | "final";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, unknown> | any[];
  key: keyof EventSettlementOverride["artist"];
}

interface UseSettlementOptions {
  enableExpenses?: boolean;
}
interface CaveError {
  message: string;
}

export function useSettlementComponent({
  enableExpenses = false,
}: UseSettlementOptions = {}) {
  const { id, type: settlementType } = useParams<{
    id: string;
    type: SettlementTab;
  }>();
  const navigate = useNavigate();
  const pageIndex = settlementType as SettlementTab;
  const setPageIndex = (settlementType: SettlementTab) => {
    navigate(`/events/settlement/${settlementType}/${id}`);
    window.scrollTo(0, 0);
  };
  const event = useQuery<CaveEvent, CaveError>(["event-detail", id], () =>
    getEvent(id)
  );
  const eventSettlement = useQuery<EventSettlementOverride, CaveError>(
    ["event-settlement", id],
    () => getEventSettlement(id)
  );
  const handleUpdateEventSettlement = async ({
    book = settlementType,
    data,
    id = event.data?.id,
    key,
  }: UpdateEventSettlementParams) => {
    const fullData = eventSettlement.data || {
      [book]: {},
    };
    if (Array.isArray(data)) {
      fullData[book][key] = data;
    } else {
      fullData[book][key] = {
        ...fullData[book][key],
        ...data,
      };
    }
    await updateEventSettlement(id, settlementType, {
      [book]: {
        ...fullData[book],
      },
    });
    queryClient.invalidateQueries(["event-detail", id]);
    queryClient.invalidateQueries(["event-settlement", id]);
    queryClient.invalidateQueries(["event-detail", id?.toString()]);
    queryClient.invalidateQueries(["event-settlement", id?.toString()]);
  };
  const queryClient = useQueryClient();
  const handleUpdateEvent = async (
    id = event.data?.id,
    data: Record<string, unknown>
  ) => {
    await updateEvent(id, {
      ...event.data,
      artists: event.data.artists?.map((artist) => artist.id),
      buyer: event.data.buyer.id,
      manifests: event.data.manifests?.map((manifest) => manifest.id),
      venue: event.data.venue.id,
      ...data,
    });
    queryClient.invalidateQueries(["event-detail", id]);
    queryClient.invalidateQueries(["event-rollups", "artist", id]);
    event.refetch();
    rollups.refetch();
  };

  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );
  const variables = useQuery(
    ["variables-offer-pdf", id],
    () => getEventOfferVariables(id),
    {
      initialData() {
        return {};
      },
    }
  );
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  const rollups = useQuery<Rollup, CaveError>(
    ["event-rollups", "artist", id],
    () => getArtistEventRollups(id)
  );
  const expensesQuery = useQuery<Expense[], CaveError>(
    ["event-expenses", id?.toString()],
    () => getEventExpenses(id, pageIndex),
    {
      initialData: [],
      enabled: enableExpenses,
    }
  );

  // computed values

  const chargebacks = expensesQuery.data?.filter(
    (expense) => expense.artist > 0 && expense.mammoth > 0
  );
  const chargebacksTotal = chargebacks?.reduce(
    (acc, chargeback) => acc + (chargeback.artist + chargeback.mammoth),
    0
  );

  const location = useLocation();

  const isPDF = location.pathname.includes("pdf");
  console.log("Current path:", location.pathname);
  console.log("isPDF value:", isPDF);

  const createExpenseMutation = useMutation({
    mutationFn: async (data: any) => createEventExpense(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id?.toString()]);
      queryClient.invalidateQueries([
        "event-expenses",
        id?.toString(),
        settlementType,
      ]);
    },
  });

  const updateExpenseMutation = useMutation({
    mutationFn: async (data: any) => updateEventExpense(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["event-expenses", id?.toString()]);
      queryClient.invalidateQueries([
        "event-expenses",
        id?.toString(),
        settlementType,
      ]);
    },
  });

  const deleteExpenseMutation = useMutation({
    mutationFn: (id) => {
      return deleteExpense(id);
    },
    onSuccess: async (id) => {
      queryClient.invalidateQueries(["event-detail", id.toString()]);
      queryClient.invalidateQueries(["variables-offer-pdf", id.toString()]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id.toString()]);
    },
  });
  return {
    id,
    pageIndex,
    setPageIndex,
    event,
    queryClient,
    handleUpdateEvent,
    eventSettlement,
    handleUpdateEventSettlement,
    settlement,
    people,
    rollups,
    isPDF,
    variables,
    expensesQuery,
    createExpenseMutation,
    updateExpenseMutation,
    deleteExpenseMutation,
    chargebacks,
    chargebacksTotal,
    settlementInputClassName: "bg-input-blue hover:bg-orange-400 duration-200",
  };
}
