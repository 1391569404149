import dayjs from "dayjs";
import { useState, useEffect, useRef } from "react";
import { EventsLoading } from "@/components/Events/EventsLoading";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";
import {
  getEventsList,
  getEventsSettings,
  getEventRoles,
  getEventPeople,
} from "@/queries/events";
import { getStaff, getPeopleSettings } from "@/queries/people";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import EventState from "@/components/EventState";
import Button from "@/components/Button";
import { usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { PeopleResult } from "@/views/events/EventPeopleAdd";

const PeopleSearchBar = ({ value, handleSelect, placeholder }) => {
  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
      return;
    }

    const url = new URL(`${window.location.origin}/api/search/people/`);
    url.searchParams.append("q", inputValue);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const options = data.hits.map((person) => ({
          value: person.id,
          label:
            `${person.first_name || ""} ${person.last_name || ""}`.trim() ||
            person.title ||
            "Unnamed Person",
        }));
        callback(options);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        callback([]);
      });
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      onChange={(selectedOption) => {
        handleSelect(
          selectedOption ? selectedOption.value : null,
          selectedOption ? selectedOption.label : ""
        );
      }}
      value={value}
      placeholder={placeholder}
      menuPlacement="top"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      className="mt-2 w-full"
      noOptionsMessage={() => "Enter a few letters"}
      isClearable
    />
  );
};

export const ExistingPeopleResults = ({ selected, setSelected, search }) => {
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (!search) return;

    const url = new URL(`${window.location.origin}/api/search/people/`);
    url.searchParams.append("q", search);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setResults(data.hits);
      });
  }, [search]);

  if (!results) {
    return (
      <div className="w-full">
        <span className="text-center dark:text-cave-white">
          {/* No similar people found */}
        </span>
      </div>
    );
  }

  return (
    <div>
      {results.map((person, index) => (
        <PeopleResult
          key={index}
          person={person}
          selected={selected}
          setSelected={(personID) => setSelected(personID, person.title)}
        />
      ))}
    </div>
  );
};

const SearchBar = ({ handleSelect, placeholder }) => {
  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
      return;
    }

    const url = new URL(`${window.location.origin}/api/search/people/`);
    url.searchParams.append("q", inputValue);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const options = data.hits.map((person) => ({
          value: person.id,
          label: person.title,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        callback([]);
      });
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      onChange={(selectedOption) => {
        handlePersonSelect(selectedOption.value, selectedOption.label);
      }}
      placeholder={placeholder}
      menuPlacement="top"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      className="mt-2"
      noOptionsMessage={() => "Enter a few letters"}
    />
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createEventPeople } from "@/queries/events";

export const EventRow = ({ event, showSelectMenu, roles = [] }) => {
  const [placeholder, setPlaceholder] = useState("Choose a role");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [selectedPersonOption, setSelectedPersonOption] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedPersonName, setSelectedPersonName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [showAddPersonButton, setShowAddPersonButton] = useState(false);
  const [showRoleSelect, setShowRoleSelect] = useState(false);

  const {
    data: eventPeople,
    isLoading,
    error,
  } = useQuery(["eventPeople", event.id], () => getEventPeople(event.id), {
    enabled: !!event.id,
  });

  const mutation = useMutation({
    mutationFn: (data) => createEventPeople(event.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["people"]);
      queryClient.invalidateQueries(["event", event.id]);
      resetView();
    },
  });

  let eventDate = dayjs(event.date).format("MMMM D, YYYY");
  if (event.date === null) {
    eventDate = "TBD";
  }

  // Use the canonical image URL from backend
  const getEventImage = () => {
    return event.event_image_url;
  };

  const goToEvent = () => window.open(`/events/${event.id}`, "_blank");

  const handlePersonSelect = (personID, personName) => {
    if (personID && personName) {
      const numericId = personID.replace("people-", "");
      setSelectedPerson(numericId);
      setSelectedPersonName(personName);
      setSelectedPersonOption({ value: personID, label: personName });
      setShowAddPersonButton(false);
      setShowRoleSelect(true);
    } else {
      // If the selection is cleared
      resetView();
    }
  };

  const handleRoleSelect = (selectedOption) => {
    setSelectedRole(selectedOption);
    setShowAddPersonButton(true);
    setPlaceholder(selectedOption ? selectedOption.label : "Choose a role");
  };

  const roleOptions = roles.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const handleAddPerson = () => {
    if (selectedPerson && selectedRole) {
      mutation.mutate(
        { people_id: selectedPerson, role_id: selectedRole.value },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["eventPeople", event.id]);
            queryClient.invalidateQueries(["people"]);
            resetView();
          },
        }
      );
    }
  };

  const resetView = () => {
    setSelectedPerson(null);
    setSelectedPersonName("");
    setSelectedRole(null);
    setShowAddPersonButton(false);
    setShowRoleSelect(false);
    setSelectedPersonOption(null); // Reset the PeopleSearchBar
  };

  // States and handlers for toggling staff list visibility
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [boxHeight, setBoxHeight] = useState("40px");
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setBoxHeight(isOpen || isHovered ? `${contentHeight}px` : "40px");
    }
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        resetView();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, isHovered, eventPeople]);

  const handleMouseEnter = () => {
    if (!isOpen) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isOpen) setIsHovered(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setIsHovered(false);
  };

  return (
    <tr className="align-top bg-cave-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
      <th
        scope="row"
        className="cursor-pointer px-6 py-4 font-medium text-cave-black whitespace-nowrap dark:text-cave-white flex items-center gap-2"
        onClick={goToEvent}
      >
        <img
          src={getEventImage()}
          alt={event.name}
          className="w-10 h-10 rounded-full object-cover"
        />
        {event.name}
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-medium text-cave-black whitespace-nowrap dark:text-cave-white"
      >
        {eventDate}
      </th>
      <td className="px-6 py-4">
        {event.venue_name && (
          <>
            {event.venue_name} - {event.venue_city}, {event.venue_state}
          </>
        )}
      </td>
      <td className="inset-y-0">
        {showSelectMenu && (
          <div className="relative flex items-start gap-2">
            {isLoading && <div>Loading Staff...</div>}
            {error && <div>Error loading Staff</div>}
            {eventPeople && (
              <div
                className="bg-[#b8b8b8] p-2 rounded transition-all duration-500 ease-in-out cursor-pointer"
                onClick={toggleOpen}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  padding: "2px",
                  margin: "2px",
                  maxHeight: boxHeight,
                  minHeight: "45px",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease-in-out",
                  width: "263px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div ref={contentRef} className="p-1">
                  <ul className="text-[black]">
                    {eventPeople.length > 0 ? (
                      eventPeople.map((person) => (
                        <li
                          key={person.id}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {person.role_name}: {person.first_name}{" "}
                          {person.last_name}
                        </li>
                      ))
                    ) : (
                      <li>No Current Staffing</li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </td>
      <td>
        <div className="relative w-full">
          <div className="flex-1 z-[10001] w-full">
            <PeopleSearchBar
              value={selectedPersonOption}
              placeholder="Search People"
              handleSelect={handlePersonSelect}
            />

            {showRoleSelect && (
              <div className="mt-4">
                <div className="p-2 block dark:text-[black] dark:bg-cave-white border-2 dark:border-color-white border-color-black">
                  Select Role for:
                  <br />
                  <span className="bg-ku-yellow font-bold ">
                    &nbsp;{selectedPersonName}&nbsp;
                  </span>
                </div>
                <Select
                  className="border border-gray-300 rounded-md p-2 w-full mt-2"
                  options={roleOptions}
                  placeholder={placeholder}
                  value={selectedRole}
                  menuPlacement="top"
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={handleRoleSelect}
                  isClearable
                />
              </div>
            )}

            {showAddPersonButton && (
              <button
                onClick={handleAddPerson}
                className={`mt-4 bg-yellow-500 hover:bg-yellow-700 text-cave-white font-bold py-2 px-4 rounded ${
                  !selectedRole ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!selectedRole} // Disable if no role is selected
              >
                Confirm Add Staff
              </button>
            )}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 text-center">
        <span className="text-gray-400">
          {event.tickets_sold} / {event.tickets_total}
        </span>
      </td>
      <td className="px-6 py-4">
        <EventState state={event.state} />
      </td>
    </tr>
  );
};

export const EventsList = (props) => {
  const [placeholder, setPlaceholder] = useState("Choose a role");
  const events = useQuery(
    ["events", "list"],
    () => getEventsList(),
    getEventsSettings
  );
  const people = useQuery(["staff"], () => getStaff(), getPeopleSettings);
  const roles = useQuery(["event-roles"], () => getEventRoles());
  const navigate = useNavigate();
  const { perms } = usePerms();
  usePageTitle(events.isSuccess ? "/events" : "/");

  const authorizedRoles = ["admin", "event_manager"];
  const isAuthorized = perms.data && authorizedRoles.includes(perms.data.role);

  const [searchTerm, setSearchTerm] = useState("");

  if (events.isLoading || people.isLoading || roles.isLoading) {
    return <EventsLoading />;
  }

  const filteredEvents = events.data.filter((event) =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const eventRoleChoices = roles.data
    .filter((role) => role.active)
    .map((role) => ({ id: role.id, name: role.name }));

  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full flex justify-between items-center mt-[.5rem]">
          <BreadCrumbs links={[{ text: "Events", url: "/events" }]} />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Filter Events by Name"
            className="w-72 p-2 border border-gray-300 rounded-md"
          />
          <Button onClick={() => navigate("/events")}>Event Cards</Button>
          <span>
            {perms.data.create_events && (
              <Button onClick={() => navigate("/events/add")}>Add Event</Button>
            )}
          </span>
        </div>
        <div className="my-2 w-full shadow-md sm:rounded-lg">
          {events.isSuccess && (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Event Name
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Venue
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/6">
                    {isAuthorized ? "Select Option" : "Status"}
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/12">
                    Sold / Available
                  </th>
                  <th scope="col" className="px-6 py-3 w-1/12"></th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event) => (
                  <EventRow
                    key={event.id}
                    event={event}
                    showSelectMenu={!isAuthorized}
                    roles={eventRoleChoices}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
