import SettlementVerticalTable from "./SettlementVerticalTable";
import SupportArtistSupports from "./SettlementArtistSupports";
import SettlementArtistAdditionals from "./SettlementArtistAdditionals";
import DealAndFinalFigures from "./SettlementArtistDealSection";
import { TableRow } from "../AppTable2";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay } from "@/utils/money";
import { UserIcon } from "@heroicons/react/24/outline";

const SettlementArtistTheDeal: React.FC = () => {
  const { chargebacks, chargebacksTotal, isPDF } = useSettlementComponent({
    enableExpenses: true,
  });
  return (
    <div>
      <div className="flex flex-col space-y-8">
        {/* Show Expenses Table */}
        <SettlementArtistAdditionals isPDF={isPDF} />
        <div className="w-full lg:w-[24%]">
          <SettlementVerticalTable
            className="w-full"
            title="PROMOTER PROFIT (LOSS)"
            data={[
              {
                label: "PROFIT (LOSS)",
                key: "profit",
                value: "$204,288.99",
              },
              {
                label: "ARTIST CHARGEBACKS",
                key: "chargebacks",
                value: amountDisplay(chargebacksTotal),
              },
            ]}
            onBlur={() => console.log("")}
            isPDF={isPDF}
          />
          <TableRow
            rowContainerClass="w-full bg-blue-200"
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "TOTAL P&L:",
              value: "$94949",
            }}
            isPDF={isPDF}
          />
        </div>
        <SupportArtistSupports />
      </div>
      <UserIcon className="h-6 w-6 text-gray-500 dark:text-gray-400 mt-4" />
    </div>
  );
};

export default SettlementArtistTheDeal;
