import React from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import SettlementTableTitle from "./SettlementTableTitle";
import { Table, TableRow } from "../AppTable2";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay } from "@/utils/money";
import SettlementArtistPayment from "./SettlementArtistPayment";
import Button from "../Button";
import { toast } from "react-toastify";

// Example usage rendering the artist section with SettlementVerticalTables and Artist Deductions
const SettlementArtistAdditionals: React.FC = () => {
  const {
    variables,
    expensesQuery,
    createExpenseMutation,
    updateExpenseMutation,
    deleteExpenseMutation,
    eventSettlement,
    rollups,
    handleUpdateEventSettlement,
    settlementInputClassName,
    isPDF,
  } = useSettlementComponent({
    enableExpenses: true,
  });
  const [isAddingDeduction, setIsAddingDeduction] = React.useState(false);
  const [deductionForm, setDeductionForm] = React.useState({
    item: "",
    paid_by: "",
    amount: 0,
  });

  if (!variables.data || !eventSettlement.data) return null;

  const reimbursements = expensesQuery.data?.filter(
    (expense) => expense.artist
  );
  const chargebacks = expensesQuery.data
    ?.filter((expense) => expense.artist > 0 && expense.mammoth > 0)
    .map((expense) => ({
      ...expense,
      value: expense.artist + expense.mammoth,
    }));
  console.log("reimbursements", reimbursements);
  const settlementDeductions = eventSettlement.data.artist.deductions;
  console.log("settlementReimbursements", settlementDeductions);
  const data = {
    artistFeeCalculation: {
      data: [
        {
          key: "Artist_Guarantee",
          label: "Guarantee",
          value: amountDisplay(variables.data?.the_deal_amount ?? 0),
          editable: false,
        },
        {
          key: "Artist_PromoterProfit",
          label: "Promoter Profit",
          value: amountDisplay(variables.data.promoter_profit),
          editable: false,
        },
        {
          key: "Artist_SplitPoint",
          label: "Split Point",
          value: amountDisplay(variables.data.split_point),
          editable: false,
        },
        {
          key: "Artist_Overages",
          label: "Overages",
          value: amountDisplay(rollups.data.overages_to_artist),
          editable: false,
        },
      ],
      total:
        (variables.data?.the_deal_amount || 0) +
        (variables.data.promoter_profit || 0) +
        (variables.data.split_point || 0) +
        (rollups.data.overages_to_artist || 0),
    },
    artistDeductions: {
      headers: [
        {
          label: "Item",
          title: "Item",
          key: "item",
          accessor: "item",
          editable: true,
          placeholder: "Name",
        },
        {
          label: "Paid By",
          title: "Paid By",
          key: "paid_by",
          accessor: "paid_by",
          editable: true,
          placeholder: "Paid By",
        },
        {
          label: "Amount",
          title: "Amount",
          key: "amount",
          accessor: "amount",
          editable: true,
          format: "money",
          placeholder: "Amount",
        },
      ],
      data: settlementDeductions ?? [],
    },
    artistReimbursements: {
      columns: [
        {
          // title: "Subgroup",
          key: "subgroup_name",
          accessor: "subgroup_name",
          editable: false,
          width: "55%",
        },
        {
          // title: "Amount",
          key: "artist",
          accessor: "artist",
          editable: false,
          format: "money",
          textAlign: "right",
        },
      ],
      total: reimbursements?.reduce(
        (acc, reimbursement) => acc + reimbursement.artist,
        0
      ),
    },
    artistPayments: {
      data: [
        {
          key: "Artist_Guarantee",
          label: "Guarantee",
          value: 125000,
          isEditable: true,
        },
        {
          key: "Artist_Overage",
          label: "Overage",
          value: 0,
          isEditable: false,
        },
        {
          key: "Artist_NetAdjustments",
          label: "Net Adjustments",
          value: 63500,
          isEditable: true,
        },
        {
          key: "Artist_TaxableAmount",
          label: "Taxable Amount",
          value: 188500,
          isEditable: false,
        },
        {
          key: "Artist_TaxState",
          label: "Tax %: State / City",
          value: 0,
          isEditable: true,
        },
        {
          key: "Artist_LessDeductions",
          label: "Less Deductions",
          value: -23850,
          isEditable: true,
        },
      ],
    },
    artistChargebacks: {
      columns: [
        {
          // title: "Subgroup",
          key: "subgroup_name",
          accessor: "subgroup_name",
          editable: false,
          width: "55%",
        },
        {
          // title: "Amount",
          accessor: "value",
          editable: false,
          format: "money",
          textAlign: "right",
        },
      ],
      total: chargebacks?.reduce(
        (acc, chargeback) => acc + (chargeback.artist + chargeback.mammoth),
        0
      ),
    },
  };
  const setData = () => ({});
  const handleVerticalBlur = (
    sectionKey: keyof typeof data,
    key: string,
    newValue: string | number
  ) => {
    const updatedData = { ...data };
    updatedData[sectionKey].data = updatedData[sectionKey].data.map((row) =>
      row.key === key ? { ...row, value: newValue } : row
    );
    setData(updatedData);
  };

  const handleArtistDeductionsBlur = (
    index: number,
    field: string,
    newValue: string | number
  ) => {
    const updatedDeductions = [...data.artistDeductions.data];
    updatedDeductions[index][field] = newValue;
    setData({
      ...data,
      artistDeductions: { ...data.artistDeductions, data: updatedDeductions },
    });
  };

  const handleAddDeduction = async (form: any) => {
    console.log("form", form);
    // const updatedDeductions = [...data.artistDeductions.data];
    // updatedDeductions.push(deductionForm);
    // setData({
    //   ...data,
    //   artistDeductions: { ...data.artistDeductions, data: updatedDeductions },
    // });
    const currDeductions = data.artistDeductions.data ?? [];
    await handleUpdateEventSettlement({
      book: "artist",
      data: [
        ...currDeductions,
        { ...form, amount: Number(deductionForm.amount) },
      ],
      key: "deductions",
    });
    toast.success("Deduction added successfully");
    setIsAddingDeduction(false);
    // setDeductionForm({
    //   item: "",
    //   paidBy: "",
    //   amount: 0,
    // });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4">
      {/* Artist Fee Calculation as vertical table */}
      <div className="border border-black self-start">
        <SettlementVerticalTable
          title="Artist Fee Calculation"
          data={data.artistFeeCalculation.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistFeeCalculation", key, newValue)
          }
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
            },
          ]}
          rowData={{
            name: "COVERAGES",
            value: amountDisplay(data.artistFeeCalculation.total),
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>

      <div className="border border-black self-start">
        {/* Artist Deductions as custom table */}
        <SettlementTableTitle>Artist Deductions</SettlementTableTitle>
        <Table
          columns={data.artistDeductions.headers}
          data={data.artistDeductions.data}
          enableAddRow={!isPDF}
          onAddRow={!isPDF ? (data) => handleAddDeduction(data) : undefined}
          inputClassName={settlementInputClassName}
          isPDF={isPDF}
          onDelete={
            !isPDF
              ? (index, row) => deleteExpenseMutation.mutate(row.id)
              : undefined
          }
          deleteIcons={!isPDF}
          deleteConfirmation="modal"
        />

        {/* Artist Chargebacks as vertical table */}
        <div className="border border-black w-full">
          <SettlementTableTitle>Artist Chargebacks</SettlementTableTitle>
          <Table
            hideHeaders
            columns={data.artistChargebacks.columns}
            data={chargebacks}
            onDelete={
              !isPDF
                ? (index, row) => deleteExpenseMutation.mutate(row.id)
                : undefined
            }
            deleteIcons={!isPDF}
            deleteConfirmation="modal"
            isPDF={isPDF}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
                editable: false,
                width: "55%",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: false,
              },
            ]}
            rowData={{
              name: "NET ADJUSTMENTS",
              value: amountDisplay(data.artistChargebacks.total),
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
      </div>

      {/* Artist Reimbursements as vertical table */}
      <div className="border border-black self-start">
        <SettlementTableTitle>Artist Reimbursements</SettlementTableTitle>
        <Table
          columns={data.artistReimbursements.columns}
          data={reimbursements}
          onDelete={
            !isPDF
              ? (index, row) => deleteExpenseMutation.mutate(row.id)
              : undefined
          }
          deleteIcons={!isPDF}
          deleteConfirmation="modal"
          isPDF={isPDF}
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
              format: "money",
            },
          ]}
          rowData={{
            name: "",
            value: data.artistReimbursements.total,
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>

      {/* Artist Payments as vertical table */}
      <div className="border border-black self-start">
        <SettlementVerticalTable
          title="Artist Payments"
          data={data.artistPayments.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistPayments", key, newValue)
          }
          onAddRow={() => ({})}
        />
        {/* <Table */}
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
              editable: false,
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
              editable: false,
            },
          ]}
          rowData={{
            name: "TOTAL DUE ARTIST",
            value: "$94949",
          }}
          rowContainerClass="bg-blue-200"
        />
        <SettlementArtistPayment
          checkNumber={{
            value: 220,
            onUpdate: (value: string) => {
              // handleBlur(section.id, "check_number", value);
            },
          }}
          paymentMethod={{
            value: 555,
            onUpdate: (value: string) => {
              // handleBlur(section.id, "payment_method", value);
            },
          }}
          paidThrough={{
            value: 330,
            onUpdate: (value: string) => {
              // handleBlur(section.id, "paid_through", value);
            },
          }}
        />
      </div>
    </div>
  );
};

export default SettlementArtistAdditionals;
