import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Header } from "@/components/Header";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { SortedTable } from "@/components/SortedTable/SortedTable";
import { getAdminReqs, updateAdminReq, getReqAdminUsers } from "@/queries/reqs";

const CustomToggleWithDialog = ({ row, propertyName, handleFieldChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState(null);

  const currentValue = row[propertyName] === "true"; // true means finalized

  const handleToggleClick = () => {
    setPendingValue(!currentValue);
    setIsOpen(true);
  };

  const handleConfirm = () => {
    if (pendingValue !== null) {
      handleFieldChange(row, propertyName, pendingValue.toString());
    }
    setIsOpen(false);
  };

  const handleCancel = () => {
    setPendingValue(null);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <div className="flex items-center gap-2">
        <button
          onClick={handleToggleClick}
          className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          style={{
            backgroundColor: currentValue ? "#10B981" : "#6B7280",
          }}
        >
          <span
            className={`inline-block h-4 w-4 transform rounded-full bg-cave-white transition duration-300 ease-in-out ${
              currentValue ? "translate-x-6" : "translate-x-1"
            }`}
          />
        </button>
        <span className="text-sm font-medium text-gray-300">
          {currentValue ? "Archive" : "Finalized"}
        </span>
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            onClick={handleCancel}
          />
          <div className="relative z-50 w-full max-w-md transform overflow-hidden rounded-lg bg-cave-white p-6 shadow-xl transition-all">
            <div className="text-center">
              <h3 className="text-lg font-medium leading-6 text-cave-black mb-4">
                Confirm Status Change
              </h3>
              <p className="text-sm text-gray-500 mb-6">
                Are you sure you want to change the status to{" "}
                {pendingValue ? "Archive" : "Finalized"}?
              </p>
            </div>
            <div className="mt-4 flex justify-end gap-3">
              <button
                onClick={handleCancel}
                className="inline-flex justify-center rounded-md border border-gray-300 bg-cave-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-cave-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ReqAdmin = () => {
  const queryClient = useQueryClient();
  const [reqsData, setReqsData] = useState([]);
  const [showArchived, setShowArchived] = useState(false);

  // Query for reqs data
  const {
    data: reqs,
    isLoading: isReqsLoading,
    isError: isReqsError,
    error: reqsError,
  } = useQuery({
    queryKey: ["reqs", showArchived],
    queryFn: () => getAdminReqs(showArchived),
    onError: (error) => console.error("Error fetching reqs:", error),
  });

  // Query for admin users
  const {
    data: adminUsers,
    isLoading: isAdminUsersLoading,
    isError: isAdminUsersError,
  } = useQuery({
    queryKey: ["adminUsers"],
    queryFn: getReqAdminUsers,
    onError: (error) => console.error("Error fetching admin users:", error),
  });

  // Format individual req data
  const formatReqData = useCallback(
    (req) => {
      console.log("Raw req before formatting:", req); // Debug log

      return {
        ...req,
        id: String(req.id),
        location: String(req.location || ""),
        description: String(req.description || ""),
        files_count: typeof req.files_count === "number" ? req.files_count : 0,
        created_by: req.created_by_name || "",
        phone: req.created_by_phone
          ? req.created_by_phone.replace(/\u202d|\u202c/g, "").trim()
          : "N/A",
        // Fix the assigned_to handling
        assigned_to: req.assigned_to ? String(req.assigned_to) : "", // Just store the ID
        assigned_to_name: (() => {
          // If we have the direct user object from the API
          if (req.assigned_to && typeof req.assigned_to === "object") {
            return `${req.assigned_to.first_name} ${req.assigned_to.last_name}`.trim();
          }
          // If we have the ID, find the user from adminUsers
          if (req.assigned_to && adminUsers) {
            const user = adminUsers.find(
              (u) => String(u.id) === String(req.assigned_to)
            );
            return user ? `${user.first_name} ${user.last_name}`.trim() : "";
          }
          return "";
        })(),
        status: String(req.status),
        created_at: String(req.created_at),
        finalized: String(!req.active), // When active is false, finalized should be "true"
        admin_comments: String(req.admin_comments || ""),
      };
    },
    [adminUsers]
  ); // Add adminUsers to dependencies

  // Update local state when reqs data changes
  useEffect(() => {
    if (reqs) {
      const formattedData = reqs.map(formatReqData);
      setReqsData(formattedData);
      console.log("Updated reqsData:", reqsData);
    }
  }, [reqs, formatReqData]);

  // Mutation for updating reqs
  const updateReqMutation = useMutation({
    mutationFn: updateAdminReq,
    onMutate: async (updatedFields) => {
      console.log("Starting mutation with fields:", updatedFields); // Debug log

      // Ensure id is a number before mutation
      const mutationFields = {
        ...updatedFields,
        id: parseInt(updatedFields.id, 10),
      };

      await queryClient.cancelQueries({ queryKey: ["reqs"] });
      const previousData = queryClient.getQueryData(["reqs"]);

      queryClient.setQueryData(["reqs"], (old) => {
        if (!old) return old;

        return old.map((req) => {
          if (String(req.id) === String(updatedFields.id)) {
            // Handle the update differently for assigned_to
            if ("assigned_to" in updatedFields) {
              const userId = updatedFields.assigned_to;
              const selectedUser = adminUsers?.find(
                (user) => String(user.id) === String(userId)
              );

              console.log("Found user for assignment:", selectedUser); // Debug log

              return {
                ...req,
                assigned_to: userId, // Store just the ID
                assigned_to_name: selectedUser
                  ? `${selectedUser.first_name} ${selectedUser.last_name}`.trim()
                  : "",
              };
            }

            return {
              ...req,
              ...updatedFields,
            };
          }
          return req;
        });
      });

      return { previousData };
    },
    onError: (err, newData, context) => {
      console.error("Mutation error:", err);
      queryClient.setQueryData(["reqs"], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["reqs"] });
    },
  });
  // Handle field changes
  const handleFieldChange = useCallback(
    (req, field, value) => {
      console.log("handleFieldChange:", { field, value, req }); // Debug log

      let updatedValue = value;

      if (field === "assigned_to") {
        updatedValue = value === "" ? null : parseInt(value, 10);

        // Optimistically update the local state
        setReqsData((prevData) =>
          prevData.map((item) => {
            if (String(item.id) === String(req.id)) {
              const selectedUser = adminUsers?.find(
                (user) => String(user.id) === String(value)
              );
              return {
                ...item,
                assigned_to: value, // Store the ID
                assigned_to_name: selectedUser
                  ? `${selectedUser.first_name} ${selectedUser.last_name}`.trim()
                  : "",
              };
            }
            return item;
          })
        );
      }

      // Call the mutation with the correct URL format
      const reqId = parseInt(req.id, 10);
      if (isNaN(reqId)) {
        console.error("Invalid req ID:", req.id);
        return;
      }

      const payload = {
        [field]: updatedValue,
        id: reqId,
      };

      updateReqMutation.mutate(payload);
    },
    [adminUsers, updateReqMutation]
  );
  // Rest of your component code remains the same...
  // (Including formatCell, headers definition, and render logic)

  const handleCommentsBlur = useCallback(
    (req, value) => {
      handleFieldChange(req, "admin_comments", value);
    },
    [handleFieldChange]
  );

  const formatCell = useCallback(
    (propertyName, row) => {
      switch (propertyName) {
        case "id":
          return (
            <a
              href={`/reqs/${row.id}`} // row.id is already a string
              className="text-blue-400 hover:text-blue-300 hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              {row.id}
            </a>
          );

        case "phone":
          return (
            <span className="text-gray-400">
              {row.phone === "N/A" ? (
                <span className="text-gray-400 italic">no phone</span>
              ) : (
                row.phone
              )}
            </span>
          );
        case "created_by":
          return (
            <span className="text-gray-300">
              {row.created_by || (
                <span className="text-gray-400 italic">no user</span>
              )}
            </span>
          );
        case "files_count":
          return (
            <span className="text-gray-400">
              {row.files_count > 0 ? (
                <span className="font-medium text-blue-400">
                  {row.files_count} file{row.files_count !== 1 ? "s" : ""}
                </span>
              ) : (
                <span className="text-gray-400 italic">no files</span>
              )}
            </span>
          );
        case "status":
          return (
            <select
              value={row[propertyName]}
              onChange={(e) =>
                handleFieldChange(row, propertyName, e.target.value)
              }
              className="bg-gray-800 border border-gray-700 text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="new">Open</option>
              <option value="in_progress">In Progress</option>
              <option value="closed">Closed</option>
            </select>
          );
        case "assigned_to":
          return (
            <select
              value={row[propertyName] || ""}
              onChange={(e) =>
                handleFieldChange(row, propertyName, e.target.value)
              }
              className="bg-gray-800 border border-gray-700 text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="">Unassigned</option>
              {adminUsers &&
                adminUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {`${user.first_name} ${user.last_name}`}
                  </option>
                ))}
            </select>
          );
        case "finalized":
          return (
            <CustomToggleWithDialog
              row={row}
              propertyName={propertyName}
              handleFieldChange={handleFieldChange}
            />
          );
        case "admin_comments":
          return (
            <textarea
              value={row[propertyName] || ""}
              onChange={(e) => {
                const newValue = e.target.value;
                setReqsData((prevData) =>
                  prevData.map((item) =>
                    item.id === row.id
                      ? { ...item, [propertyName]: newValue }
                      : item
                  )
                );
              }}
              onBlur={(e) => handleCommentsBlur(row, e.target.value)}
              className="bg-gray-800 border border-gray-700 text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          );
        case "created_at":
          return new Date(row.created_at).toLocaleString();
        default:
          return <span className="text-gray-300">{row[propertyName]}</span>;
      }
    },
    [adminUsers, handleFieldChange, handleCommentsBlur]
  );

  if (isReqsLoading || isAdminUsersLoading)
    return <div className="text-center text-gray-300">Loading...</div>;
  if (isReqsError)
    return <div>Error loading requests: {reqsError.message}</div>;
  if (isAdminUsersError) return <div>Error loading admin users</div>;

  const headers = [
    {
      header: "ID",
      accessor: "id",
      type: "number",
      style: { width: "80px", maxWidth: "80px" },
    },
    {
      header: "Location",
      accessor: "location",
      type: "string",
      style: { maxWidth: "50px" },
    },
    {
      header: "Description",
      accessor: "description",
      type: "string",
      className: "max-w-xs",
      truncate: true,
    },
    { header: "Created By", accessor: "created_by", type: "string" },
    { header: "Phone", accessor: "phone", type: "string" },
    {
      header: "Files",
      accessor: "files_count",
      type: "number",
      style: { width: "120px", maxWidth: "120px" },
    },
    {
      header: "Status",
      accessor: "status",
      type: "string",
      className: "min-w-md flex",
      truncate: false,
    },
    {
      header: "Created At",
      accessor: "created_at",
      type: "string",
      style: { width: "222px", maxWidth: "222px" },
    },
    {
      header: "Assigned To",
      accessor: "assigned_to",
      type: "string",
      style: { width: "222px", maxWidth: "222px" },
    },
    { header: "", accessor: "finalized", type: "string" },
    { header: "Admin Comments", accessor: "admin_comments", type: "string" },
  ];
  const handleRowClick = (row) => {
    navigate(`/reqs/${row.id}`);
  };
  return (
    <>
      <Header />
      <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
        <BreadCrumbs links={[{ text: "Admin Requests", url: "/reqs/admin" }]} />
        <div className="flex justify-center mb-4 mt-4">
          <button
            onClick={() => setShowArchived(!showArchived)}
            className={`px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
              showArchived
                ? "bg-gray-600 hover:bg-gray-700"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {showArchived ? "Showing Archived" : "Hiding Archived"}
          </button>
        </div>
        <SortedTable
          data={reqsData}
          headers={headers}
          formatCell={formatCell}
          hasFilter={true}
          hasHeader={false}
          hasSorting={true}
          handleRowClick={handleRowClick}
          initiallyOpen={true}
          initialSortKey="id"
          initialSortOrder="asc"
          customSort={(key, a) => {
            if (key === "assigned_to") {
              return a.assigned_to_name.toLowerCase();
            }
            if (key === "created_at") {
              return new Date(a.created_at).getTime();
            }
            return typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
          }}
        />
      </div>
    </>
  );
};
