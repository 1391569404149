import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SunIcon, MoonIcon } from "@heroicons/react/24/outline";

// Function to determine if dark mode is enabled based on local storage, defaulting to dark
const isDark = () => {
  return localStorage.getItem("color-theme") !== "light";
};

const determineIcon = (dark) => {
  return dark ? MoonIcon : SunIcon;
};

// Function to apply the theme based on URL and dark mode setting
const applyTheme = (dark, location) => {
  // Only force light mode for settlement and offer pages
  const forceLightModePatterns = [
    /^\/events\/settlement\/.*$/,
    /^\/events\/edit\/offer\/.*$/,
  ];

  const shouldForceLightMode = forceLightModePatterns.some((pattern) =>
    pattern.test(location.pathname)
  );

  // Force light mode for specific pages, dark mode everywhere else
  if (shouldForceLightMode) {
    document.documentElement.classList.remove("dark");
  } else {
    document.documentElement.classList.add("dark");
  }
};

export const DarkToggle = () => {
  const [dark, setDark] = useState(isDark());
  const location = useLocation();

  // Determine the correct icon based on the dark mode state
  let Icon = determineIcon(dark);

  // Use effect to apply theme when location or dark mode changes
  useEffect(() => {
    const isDark = localStorage.getItem("color-theme") === "dark";
    setDark(isDark);
    applyTheme(isDark, location); // Reapply theme whenever location changes
  }, [location]);

  const handleButtonPress = () => {
    const newDark = !dark;
    setDark(newDark);
    applyTheme(newDark, location);
    localStorage.setItem("color-theme", newDark ? "dark" : "light");
  };

  return (
    <>
      <div className="hidden">
        <button
          type="button"
          data-dropdown-toggle="apps-dropdown"
          className="p-2 text-gray-500 rounded-lg hover:text-cave-black hover:bg-gray-100 dark:text-gray-400 dark:hover:text-cave-white dark:hover:bg-gray-700"
          onClick={handleButtonPress} // Toggle dark mode on button press
        >
          <span className="sr-only">Toggle dark mode</span>
          <Icon className="w-6 h-6 hover:text-blue-500" />
        </button>
      </div>
    </>
  );
};
