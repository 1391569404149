import React, { useState } from "react";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";

export function PPeopleIcon({ person, debugInfo = {} }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: "50px",
  });

  const getImageUrl = () => {
    // Direct photo URL passed in
    if (typeof person === "string") {
      return person;
    }
    // Check for old format (person.user.photo)
    if (person?.user?.photo) {
      return person.user.photo;
    }
    // Check for buyer_image_url from API (first buyer's image)
    if (person?.buyer_image_url) {
      return person.buyer_image_url;
    }
    // Check for new format (person.photo)
    if (person?.photo) {
      return person.photo;
    }
    // Fall back to images array if neither photo exists
    if (person?.images && person.images.length > 0) {
      return person.images[0].image;
    }
    return null;
  };

  const imageUrl = getImageUrl();

  const initials =
    person?.first_name && person?.last_name
      ? `${person.first_name[0]}${person.last_name[0]}`.toUpperCase()
      : person?.first_name
      ? person.first_name[0].toUpperCase()
      : "?";

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const handleError = () => {
    setHasError(true);
  };

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        e.preventDefault();
        console.log("PPeopleIcon Debug Info:", {
          ...debugInfo,
          receivedPerson: person,
          personId: person?.person_id || person?.id,
          buyerId: person?.buyer_id,
          buyerImageUrl: person?.buyer_image_url,
        });
        // Try person_id first (from API), fall back to id (direct person object)
        const personId = person?.person_id || person?.id;
        if (personId) {
          window.open(`/people/${personId}`, "_blank", "noopener,noreferrer");
        } else {
          console.warn("No valid person ID found:", person);
        }
      }}
      href={`/people/${person?.person_id || person?.id}`}
    >
      <div
        ref={ref}
        className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden relative"
      >
        {imageUrl ? (
          <>
            {isIntersecting && !hasError && imageUrl && (
              <img
                src={imageUrl}
                alt={initials}
                className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
                  isLoaded ? "opacity-100" : "opacity-0"
                }`}
                onLoad={handleLoad}
                onError={handleError}
              />
            )}
            {!isLoaded && !hasError && (
              <div className="spinner" role="status" aria-label="Loading" />
            )}
            {hasError && (
              <span className="text-xl font-semibold text-gray-500">
                {initials}
              </span>
            )}
          </>
        ) : (
          <span className="text-xl font-semibold text-gray-500">
            {initials}
          </span>
        )}
      </div>
    </a>
  );
}

// Keep the old version for backwards compatibility
export function PeopleIcon({ imageUrl, person_id }) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={`/people/${person_id}`}>
      <div
        className="w-12 h-12 rounded-full bg-cover bg-center"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
    </a>
  );
}
