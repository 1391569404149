import React from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { bundleAllExpenses } from "@/helpers";
import { useFinalFigures } from "@/hooks/useFinalFigures";

export default function SettlementDueGrid() {
  const { expensesQuery, isPDF } = useSettlementComponent({
    enableExpenses: true,
  });
  const artistFinalFigures = useFinalFigures("artist");
  const dueMammothData = [
    {
      key: "mammothPercent",
      label: "Mammoth %",
      value: "100.00%",
      isEditable: true,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$(170,624.89)",
      isEditable: false,
    },
    {
      key: "expenses",
      label: "Plus Expenses",
      value: bundleAllExpenses(expensesQuery.data).mammoth,
      format: "money",
      isEditable: false,
    },
    {
      key: "guarantees",
      label: "Plus Gurantee, Ovg, Bonus",
      // TODO: get this from revenues table
      value: 388,
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: bundleAllExpenses(expensesQuery.data).artist,
      format: "money",
      isEditable: false,
    },
    {
      key: "tax",
      label: "Plus Tax",
      value: artistFinalFigures?.dataToUse("tax1"),
      isEditable: true,
    },
    {
      key: "facility_fee",
      label: "Plus FAC FEE",
      value: artistFinalFigures?.dataToUse("facility_fee"),
      isEditable: true,
    },
    {
      key: "platinum_lift",
      label: "PLUS PLATINUM %",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: true,
    },
    {
      key: "chariiy",
      label: "PLUS CHARITY",
      value: artistFinalFigures?.dataToUse("charity"),
      isEditable: true,
    },
    {
      key: "misc",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: true,
    },
    {
      key: "misc_read_only",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: false,
    },
    {
      key: "less_rebate",
      label: "LESS REBATE",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_guarantee",
      label: "LESS GUARANTEE DEDUCTIONS",
      value: artistFinalFigures?.dataToUse("less_guarantee"),
      isEditable: true,
    },
    {
      key: "less_cash",
      label: "LESS CASH",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_misc",
      label: "LESS MISC",
      value: artistFinalFigures?.dataToUse("less_misc"),
      isEditable: true,
    },
    // TODO: Ticket rebates - Merch Revenue
    {
      key: "less_revenue",
      label: "LESS ANCILLARY REVENUE",
      value: artistFinalFigures?.dataToUse("less_revenue"),
      isEditable: true,
    },
    {
      key: "taxLess",
      label: "Less Tax",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_platinum",
      label: "LESS PLATINUM",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: false,
    },
    // TODO: ticketing gross - due building ticketing gross
    {
      key: "less_ticketing",
      label: "Less TICKETING GROSS REVENUE",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_cash",
      label: "LESS CASH (received)",
      value: artistFinalFigures?.dataToUse("less_cash"),
      isEditable: false,
    },
  ];

  const dueBuildingData = [
    {
      key: "mammothPercent",
      label: "Mammoth %",
      value: "100.00%",
      isEditable: true,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$(170,624.89)",
      isEditable: false,
    },
    {
      key: "expenses",
      label: "Plus Expenses",
      value: bundleAllExpenses(expensesQuery.data).venue,
      format: "money",
      isEditable: false,
    },
    {
      key: "guarantees",
      label: "Plus Gurantee, Ovg, Bonus",
      // TODO: get this from revenues table
      value: 388,
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: bundleAllExpenses(expensesQuery.data).artist,
      format: "money",
      isEditable: false,
    },
    {
      key: "tax",
      label: "Plus Tax",
      value: artistFinalFigures?.dataToUse("tax1"),
      isEditable: true,
    },
    {
      key: "facility_fee",
      label: "Plus FAC FEE",
      value: artistFinalFigures?.dataToUse("facility_fee"),
      isEditable: true,
    },
    {
      key: "platinum_lift",
      label: "PLUS PLATINUM %",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: true,
    },
    {
      key: "chariiy",
      label: "PLUS CHARITY",
      value: artistFinalFigures?.dataToUse("charity"),
      isEditable: true,
    },
    {
      key: "misc",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: true,
    },
    {
      key: "misc_read_only",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: false,
    },
    {
      key: "less_rebate",
      label: "LESS REBATE",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_guarantee",
      label: "LESS GUARANTEE DEDUCTIONS",
      value: artistFinalFigures?.dataToUse("less_guarantee"),
      isEditable: true,
    },
    {
      key: "less_cash",
      label: "LESS CASH",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_misc",
      label: "LESS MISC",
      value: artistFinalFigures?.dataToUse("less_misc"),
      isEditable: true,
    },
    // TODO: Ticket rebates - Merch Revenue
    {
      key: "less_revenue",
      label: "LESS ANCILLARY REVENUE",
      value: artistFinalFigures?.dataToUse("less_revenue"),
      isEditable: true,
    },
    {
      key: "taxLess",
      label: "Less Tax",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_platinum",
      label: "LESS PLATINUM",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: false,
    },
    // TODO: ticketing gross - due building ticketing gross
    {
      key: "less_ticketing",
      label: "Less TICKETING GROSS REVENUE",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_cash",
      label: "LESS CASH (received)",
      value: artistFinalFigures?.dataToUse("less_cash"),
      isEditable: false,
    },
  ];

  const dueCopro1Data = [
    {
      key: "mammothPercent",
      label: "Mammoth %",
      value: "100.00%",
      isEditable: true,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$(170,624.89)",
      isEditable: false,
    },
    {
      key: "expenses",
      label: "Plus Expenses",
      value: bundleAllExpenses(expensesQuery.data).partner_1,
      format: "money",
      isEditable: false,
    },
    {
      key: "guarantees",
      label: "Plus Gurantee, Ovg, Bonus",
      // TODO: get this from revenues table
      value: 388,
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: bundleAllExpenses(expensesQuery.data).artist,
      format: "money",
      isEditable: false,
    },
    {
      key: "tax",
      label: "Plus Tax",
      value: artistFinalFigures?.dataToUse("tax1"),
      isEditable: true,
    },
    {
      key: "facility_fee",
      label: "Plus FAC FEE",
      value: artistFinalFigures?.dataToUse("facility_fee"),
      isEditable: true,
    },
    {
      key: "platinum_lift",
      label: "PLUS PLATINUM %",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: true,
    },
    {
      key: "chariiy",
      label: "PLUS CHARITY",
      value: artistFinalFigures?.dataToUse("charity"),
      isEditable: true,
    },
    {
      key: "misc",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: true,
    },
    {
      key: "misc_read_only",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: false,
    },
    {
      key: "less_rebate",
      label: "LESS REBATE",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_guarantee",
      label: "LESS GUARANTEE DEDUCTIONS",
      value: artistFinalFigures?.dataToUse("less_guarantee"),
      isEditable: true,
    },
    {
      key: "less_cash",
      label: "LESS CASH",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_misc",
      label: "LESS MISC",
      value: artistFinalFigures?.dataToUse("less_misc"),
      isEditable: true,
    },
    // TODO: Ticket rebates - Merch Revenue
    {
      key: "less_revenue",
      label: "LESS ANCILLARY REVENUE",
      value: artistFinalFigures?.dataToUse("less_revenue"),
      isEditable: true,
    },
    {
      key: "taxLess",
      label: "Less Tax",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_platinum",
      label: "LESS PLATINUM",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: false,
    },
    // TODO: ticketing gross - due building ticketing gross
    {
      key: "less_ticketing",
      label: "Less TICKETING GROSS REVENUE",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_cash",
      label: "LESS CASH (received)",
      value: artistFinalFigures?.dataToUse("less_cash"),
      isEditable: false,
    },
  ];

  const dueCopro2Data = [
    {
      key: "mammothPercent",
      label: "Mammoth %",
      value: "100.00%",
      isEditable: true,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$(170,624.89)",
      isEditable: false,
    },
    {
      key: "expenses",
      label: "Plus Expenses",
      value: bundleAllExpenses(expensesQuery.data).partner_2,
      format: "money",
      isEditable: false,
    },
    {
      key: "guarantees",
      label: "Plus Gurantee, Ovg, Bonus",
      // TODO: get this from revenues table
      value: 388,
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: bundleAllExpenses(expensesQuery.data).artist,
      format: "money",
      isEditable: false,
    },
    {
      key: "tax",
      label: "Plus Tax",
      value: artistFinalFigures?.dataToUse("tax1"),
      isEditable: true,
    },
    {
      key: "facility_fee",
      label: "Plus FAC FEE",
      value: artistFinalFigures?.dataToUse("facility_fee"),
      isEditable: true,
    },
    {
      key: "platinum_lift",
      label: "PLUS PLATINUM %",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: true,
    },
    {
      key: "chariiy",
      label: "PLUS CHARITY",
      value: artistFinalFigures?.dataToUse("charity"),
      isEditable: true,
    },
    {
      key: "misc",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: true,
    },
    {
      key: "misc_read_only",
      label: "PLUS MISC",
      value: artistFinalFigures?.dataToUse("misc"),
      isEditable: false,
    },
    {
      key: "less_rebate",
      label: "LESS REBATE",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_guarantee",
      label: "LESS GUARANTEE DEDUCTIONS",
      value: artistFinalFigures?.dataToUse("less_guarantee"),
      isEditable: true,
    },
    {
      key: "less_cash",
      label: "LESS CASH",
      value: artistFinalFigures?.dataToUse("less_rebate"),
      isEditable: true,
    },
    {
      key: "less_misc",
      label: "LESS MISC",
      value: artistFinalFigures?.dataToUse("less_misc"),
      isEditable: true,
    },
    // TODO: Ticket rebates - Merch Revenue
    {
      key: "less_revenue",
      label: "LESS ANCILLARY REVENUE",
      value: artistFinalFigures?.dataToUse("less_revenue"),
      isEditable: true,
    },
    {
      key: "taxLess",
      label: "Less Tax",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_platinum",
      label: "LESS PLATINUM",
      value: artistFinalFigures?.dataToUse("platinum_lift"),
      isEditable: false,
    },
    // TODO: ticketing gross - due building ticketing gross
    {
      key: "less_ticketing",
      label: "Less TICKETING GROSS REVENUE",
      value: artistFinalFigures?.dataToUse("platin"),
      isEditable: false,
    },
    {
      key: "less_cash",
      label: "LESS CASH (received)",
      value: artistFinalFigures?.dataToUse("less_cash"),
      isEditable: false,
    },
  ];
  const classBinds = {
    valueClass: "text-right px-1.5",
    labelClass: "px-1.5",
  };
  return (
    <div className="!bg-white grid grid-cols-1 lg:grid-cols-4 gap-y-4">
      <SettlementVerticalTable
        title="Due Mammoth"
        data={dueMammothData}
        onBlur={(key, newValue) => {
          console.log(`Updated Due Mammoth - Field ${key}: ${newValue}`);
        }}
        {...classBinds}
      />
      <SettlementVerticalTable
        title="Due Building"
        data={dueBuildingData}
        valueClass="text-right"
        onBlur={(key, newValue) => {
          console.log(`Updated Due Building - Field ${key}: ${newValue}`);
        }}
        {...classBinds}
      />
      <SettlementVerticalTable
        title="Due Copro 1"
        data={dueCopro1Data}
        valueClass="text-right"
        onBlur={(key, newValue) => {
          console.log(`Updated Due Copro 1 - Field ${key}: ${newValue}`);
        }}
        {...classBinds}
      />
      <SettlementVerticalTable
        title="Due Copro 2"
        data={dueCopro2Data}
        valueClass="text-right"
        onBlur={(key, newValue) => {
          console.log(`Updated Due Copro 2 - Field ${key}: ${newValue}`);
        }}
        {...classBinds}
      />
    </div>
  );
}
