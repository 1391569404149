import { jsonHeaders, makeRequest } from "./base";

export const createMessage = async (data) => {
  const headers = await jsonHeaders(true);
  return makeRequest(
    "/api/bulletin/messages/",
    "POST",
    headers,
    JSON.stringify(data)
  );
};
