import { createContext, useContext, useState, useEffect } from "react";

const LoadingContext = createContext();

export function LoadingProvider({ children }) {
  const [loadingState, setLoadingState] = useState("loading");
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (document.readyState === "complete") {
      setComponentLoaded(true);
    }
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        loadingState,
        setLoadingState,
        componentLoaded,
        setComponentLoaded,
        loadError,
        setLoadError,
        progress,
        setProgress,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
}
