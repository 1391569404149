// SupportArtistSupports.tsx

import React from "react";
import SettlementVerticalTable from "./SettlementVerticalTable"; // Adjust the import path as needed
import { TableRow } from "../AppTable2"; // Adjust the import path as needed
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay } from "@/utils/money";
import SettlementArtistPayment from "./SettlementArtistPayment"; // Adjust the import path as needed

const SupportArtistSupports: React.FC = () => {
  const { eventSettlement, event, handleUpdateEventSettlement } =
    useSettlementComponent();

  const artistSupports = eventSettlement.data?.artist?.supports ?? [];

  const getArtistSupportItem = React.useCallback(
    (key: "offer_support_1" | "offer_support_2") =>
      artistSupports?.find((item) => item.id === key),
    [artistSupports]
  );

  // Memoize the supportData to prevent unnecessary re-renders
  const supportData = React.useMemo(() => {
    const supportDataArray = [];
    const hasSupport1 = !!event.data?.offer_support_1;
    const hasSupport2 = !!event.data?.offer_support_2;
    if (!hasSupport1 && !hasSupport2) return [];
    const baseObject = (key: "offer_support_1" | "offer_support_2") => {
      const id = key;
      const artistSupportItem = getArtistSupportItem(key);
      const total =
        (artistSupportItem?.guarantee ?? 0) +
        (artistSupportItem?.adjustments ?? 0) +
        (artistSupportItem?.misc ?? 0) +
        (artistSupportItem?.less_deposit ?? 0);
      return {
        id: id,
        title: event.data?.[key],
        total,
        rows: [
          {
            key: "guarantee",
            label: "Guarantee",
            value: artistSupportItem?.guarantee ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "adjustments",
            label: "Plus Artist Adjustments",
            value: artistSupportItem?.adjustments ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "misc",
            label: "Plus Misc",
            value: artistSupportItem?.misc ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "tax_percent",
            label: "Tax %",
            value: artistSupportItem?.tax_percent ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "less_deposit",
            label: "Less Deposit",
            value: artistSupportItem?.less_deposit ?? 0,
            isEditable: true,
            format: "money",
          },
          {
            key: "total_due",
            label: "Total Due",
            value: artistSupportItem?.total_due ?? 0,
            isEditable: true,
            format: "money",
          },
        ],
      };
    };

    if (hasSupport1) {
      supportDataArray.push(baseObject("offer_support_1"));
    }
    if (hasSupport2) {
      supportDataArray.push(baseObject("offer_support_2"));
    }
    return supportDataArray;
  }, [event.data, getArtistSupportItem]);

  const handleBlur = (id: string, key: string, value: string | number) => {
    const newValue = value;
    const isString = isNaN(Number(value));
    if (!isString) {
      //   newValue = amountStrToInt(value);
    }
    const updatedSupports = artistSupports.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: newValue, // update the specific key with the new value
        };
      }
      return item;
    });

    // If the support with the given id does not exist, add it to the array
    if (!artistSupports.some((item) => item.id === id)) {
      const newSupport = {
        id,
        guarantee: 0,
        adjustments: 0,
        misc: 0,
        tax_percent: 0,
        less_deposit: 0,
        total_due: 0,
        payment_method: "",
        check_number: "",
        paid_through: "",
        [key]: newValue,
      };
      updatedSupports.push(newSupport);
    }

    handleUpdateEventSettlement({
      data: updatedSupports,
      key: "supports",
      book: "artist",
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4">
      {supportData.map((section, index) => {
        // Memoize the payment props to prevent re-renders
        const checkNumber = React.useMemo(
          () => ({
            value: getArtistSupportItem(section.id)?.check_number ?? "",
            onUpdate: (value: string) => {
              handleBlur(section.id, "check_number", value);
            },
          }),
          [
            section.id,
            getArtistSupportItem(section.id)?.check_number,
            handleBlur,
          ]
        );

        const paymentMethod = React.useMemo(
          () => ({
            value: getArtistSupportItem(section.id)?.payment_method ?? "",
            onUpdate: (value: string) => {
              handleBlur(section.id, "payment_method", value);
            },
          }),
          [
            section.id,
            getArtistSupportItem(section.id)?.payment_method,
            handleBlur,
          ]
        );

        const paidThrough = React.useMemo(
          () => ({
            value: getArtistSupportItem(section.id)?.paid_through ?? "",
            onUpdate: (value: string) => {
              handleBlur(section.id, "paid_through", value);
            },
          }),
          [
            section.id,
            getArtistSupportItem(section.id)?.paid_through,
            handleBlur,
          ]
        );

        return (
          <div key={section.id}>
            <SettlementVerticalTable
              title={`Support ${index + 1} (${section.title})`}
              data={section.rows ?? []}
              onBlur={(key, newValue) => {
                handleBlur(section.id, key, newValue);
              }}
            />
            <TableRow
              columns={[
                {
                  key: "name",
                  accessor: "name",
                  textAlign: "left",
                  editable: false,
                },
                {
                  key: "value",
                  accessor: "value",
                  textAlign: "right",
                  editable: false,
                },
              ]}
              rowData={{
                name: "TOTAL DUE",
                value: section?.total
                  ? amountDisplay(section.total)
                  : amountDisplay(0),
              }}
              rowContainerClass="bg-blue-200 p-0.5"
            />
            <SettlementArtistPayment
              key={section.id}
              checkNumber={checkNumber}
              paymentMethod={paymentMethod}
              paidThrough={paidThrough}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SupportArtistSupports;
