import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { FormBuilder, Input, TextInput } from "@/components/Form";
import { createPerson } from "@/queries/people";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";

const PeopleResult = (props) => {
  const { person } = props;
  console.dir(person);
  return (
    <div className="mt-2 border border-1 border-blue-200 p-2 hover:bg-blue-200 cursor-pointer">
      <h1 className="dark:text-cave-white">{person.title}</h1>
      <h2 className="text-gray-500">{person.description}</h2>
    </div>
  );
};

const ExistingPeopleResults = () => {
  const [results, setResults] = useState(null);
  const [search, setSearch] = useState("");
  const { formState, watch } = useFormContext();

  useEffect(() => {
    const subscription = watch(async (values, { name, type }) => {
      console.log(values, name, type);

      let peopleParts = [];

      if (values.first_name) {
        peopleParts.push(values.first_name);
      }

      if (values.last_name) {
        peopleParts.push(values.last_name);
      }

      if (values.company_name) {
        peopleParts.push(values.company_name);
      }
      if (values.email) {
        peopleParts.push(values.email);
      }

      const query = peopleParts.join(" ");
      setSearch(query);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!search) {
      setResults(null);
      return;
    }
    const url = new URL(`${window.location.origin}/api/search/people/`);
    url.searchParams.append("q", search);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("[--- Results ---]");
        console.dir(data);
        if (data.error) {
          console.error("Search error:", data.error);
          setResults(null);
        } else {
          setResults(data.hits);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        setResults(null);
      });
  }, [search]);

  if (!results || results.length === 0) {
    return (
      <div className="w-full">
        <span className="text-center dark:text-cave-white">
          No similar people found
        </span>
      </div>
    );
  }

  return (
    <div>
      {results.map((person, index) => (
        <PeopleResult key={index} person={person} />
      ))}
    </div>
  );
};

export const PeopleAdd = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating person");
      console.dir(data);

      return createPerson(data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["people"]);
      navigate(`/people/${data.id}`);
    },
  });

  const updateSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    company_name: yup.string(),
    description: yup.string(),
    website_url: yup.string().url(),
    email: yup.string().email(),
    phone: yup.string(),
    address_line_1: yup.string(),
    address_line_2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
  });

  const handleSubmit = (data) => {
    console.log("In submit");
    console.dir(data);
    mutation.mutate(data);
  };

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[{ text: "People", url: "/people" }, { text: "Add Person" }]}
        />

        <h3 className="text-xl font-bold mb-4 dark:text-cave-white">
          Add New Person
        </h3>
        <p className="my-4 text-gray-600 dark:text-gray-400">
          As you type their name, company, and/or email similar results will
          show up to the right. If you see the same person, please do not add
          them again as this makes keeping contact information in sync
          challenging.
        </p>
        <FormBuilder
          onSubmit={(data) => handleSubmit(data)}
          schema={updateSchema}
        >
          <div className="flex">
            <div className="w-3/4">
              <Input name="first_name" label="First Name" autoFocus={true} />
              <Input name="last_name" label="Last Name" />
              <Input name="company_name" label="Company Name" />
              <Input name="email" label="Email" />
              <Input name="phone" label="Phone" />
              <TextInput name="description" label="Description" />
              <Input name="website_url" label="Website URL" />
              <Input name="address_line_1" label="Address 1" />
              <Input name="address_line_2" label="Address 2" />
              <Input name="city" label="City" />
              <Input name="state" label="State" />
              <Input name="zipcode" label="Zipcode" />

              <div className="flex-inline float-right mb-12">
                <button
                  type="button"
                  onClick={() => navigate(`/people/`)}
                  className="ml-4 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Cancel
                </button>
                <Button type="submit">Add Person</Button>
              </div>
            </div>

            <div className="ml-4 mb-12 p-2 border border-1 border-blue-200 w-1/2">
              <h3 className="font-semibold text-gray-400 dark:text-gray-200">
                Possible Existing People
              </h3>
              <ExistingPeopleResults />
            </div>
          </div>
        </FormBuilder>
      </div>
    </>
  );
};
