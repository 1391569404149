import { toast } from "react-toastify";

interface ManifestBackup {
  eventId: string;
  data: any;
  timestamp: number;
}

const BACKUP_KEY_PREFIX = "manifest_backup_";
const MAX_BACKUP_AGE_MS = 24 * 60 * 60 * 1000; // 24 hours

export const saveManifestBackup = (eventId: string, data: any) => {
  try {
    const backup: ManifestBackup = {
      eventId,
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(
      `${BACKUP_KEY_PREFIX}${eventId}`,
      JSON.stringify(backup)
    );
  } catch (error) {
    console.error("Failed to save manifest backup:", error);
  }
};

export const getManifestBackup = (eventId: string): ManifestBackup | null => {
  try {
    const backup = localStorage.getItem(`${BACKUP_KEY_PREFIX}${eventId}`);
    if (!backup) return null;

    const parsed = JSON.parse(backup) as ManifestBackup;

    // Check if backup is too old
    if (Date.now() - parsed.timestamp > MAX_BACKUP_AGE_MS) {
      localStorage.removeItem(`${BACKUP_KEY_PREFIX}${eventId}`);
      return null;
    }

    return parsed;
  } catch (error) {
    console.error("Failed to retrieve manifest backup:", error);
    return null;
  }
};

export const clearManifestBackup = (eventId: string) => {
  try {
    localStorage.removeItem(`${BACKUP_KEY_PREFIX}${eventId}`);
  } catch (error) {
    console.error("Failed to clear manifest backup:", error);
  }
};

export const checkForBackup = (eventId: string): boolean => {
  const backup = getManifestBackup(eventId);
  return backup !== null;
};
