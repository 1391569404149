import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getVenues, getVenuesSettings } from "@/queries/venues";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import Button from "@/components/Button";
import { usePageTitle } from "@/utils/pagetitle";
import { useUser, usePerms } from "@/components/Auth";
import { SortedTable } from "@/components/SortedTable/SortedTable";

const VenueRow = (props) => {
  const { venue } = props;
  const navigate = useNavigate();

  return (
    <tr
      className="bg-cave-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      onClick={() => {
        navigate(`/venues/${venue.id}`);
      }}
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-cave-black whitespace-nowrap dark:text-cave-white"
      >
        {venue.name}
      </th>
      <td className="px-6 py-4">
        {venue.city}, {venue.state}
      </td>
      <td className="px-6 py-4">{venue.state}</td>
      <td className="px-6 py-4">{venue.capacity}</td>
    </tr>
  );
};

const FilteredVenuesTable = ({
  venues,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const navigate = useNavigate();

  const formatData = (venues) => {
    const filteredVenues = venues.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return filteredVenues;
  };

  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      return rowData["on_sale"]
        ? dayjs(rowData["on_sale"]).format("MMM D, YYYY")
        : "No Date";
    }
    // return nothing for all other values
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    } else {
      return item[key];
    }
  };

  return (
    <SortedTable
      total={total}
      data={formatData(venues)}
      headers={headers}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      handleRowClick={(row, index) => navigate(`/venues/${row.id}`)}
      hasSorting
      hasFilter
    />
  );
};

export const VenuesHome = () => {
  const venues = useQuery(["venues"], () => getVenues(), getVenuesSettings);
  const navigate = useNavigate();
  const { perms } = usePerms();
  const { user } = useUser();
  const filteredVenues = venues?.data || [];
  const [isFadingOut, setIsFadingOut] = useState(false);
  usePageTitle(
    venues.isSuccess
      ? `/c${String.fromCharCode(92)}venues`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  useEffect(() => {
    if (venues.isLoading) {
      setIsFadingOut(false);
    } else if (venues.isSuccess) {
      setTimeout(() => {
        setIsFadingOut(true);
      }, 1000); // Adjust the delay as needed
    }
  }, [venues.isLoading, venues.isSuccess]);

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0"
      >
        <div className="w-full flex justify-between items-center flex-col sm:flex-row mb-[.5rem] sm:mb-0">
          <BreadCrumbs links={[{ text: "Venues", url: "/venues" }]} />

          {perms.data.edit_venues && (
            <>
              <span>
                <Button onClick={() => navigate("/venues/add")}>
                  Add Venue
                </Button>
              </span>
              <Button onClick={() => navigate("/venues/seatingcharts")}>
                Seating Charts Admin
              </Button>
            </>
          )}
        </div>

        <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
          <FilteredVenuesTable
            venues={filteredVenues}
            headers={[
              { header: "NAME", accessor: "name" },
              { header: "CITY", accessor: "city" },
              { header: "STATE", accessor: "state" },
              { header: "CAPICITY", accessor: "capacity" },
            ]}
          />
        </div>
      </div>
    </>
  );
};
