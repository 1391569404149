import React, { useEffect, useRef, useState } from "react";
import { amountDisplay } from "@/utils/money";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import { FIELD_UPDATE_ANIMATION_TIME } from "../views/events/EventEditOffer";

export const WalkoutPot = ({
  dealHeader,
  offer_type,
  the_deal_amount,
  split_point,
  promoter_profit,
  walkout_potential,
  showHeader,
  toggleHeader,
}) => {
  const walkoutPotentialRef = useRef(walkout_potential);
  const [isChanged, setIsChanged] = useState(false);

  //  useEffect(() => {
  //    if(walkoutPotentialRef.current !== walkout_potential) {
  //      setIsChanged(true)
  //      walkoutPotentialRef.current = walkout_potential
  //    }
  //  }, [walkout_potential])
  //
  //useEffect(() => {
  //  if(isChanged) {
  //    setTimeout(() => {
  //      setIsChanged(false)
  //    }, FIELD_UPDATE_ANIMATION_TIME)
  //  }
  //}, [isChanged])

  return (
    <div
      className={`rounded overflow-hidden z-[10001] w-88 fixed right-4 ${
        showHeader ? "top-2" : ""
      } ${!isChanged ? "ring ring-ku-red/50" : ""}`}
    >
      <div
        className={`bg-ku-blue p-2 shadow-md ${
          isChanged ? "glow-text-gradient-border" : ""
        }`}
      >
        <div className="flex items-center">
          <button
            className="p-2 rounded-full bg-gray-200 focus:outline-none"
            onClick={toggleHeader}
          >
            {showHeader ? (
              <FiMinimize2 className="text-gray-600" size={12} />
            ) : (
              <FiMaximize2 className="text-gray-600" size={12} />
            )}
          </button>
          <div className="pl-2 w-88 items-end">
            {dealHeader !== 0 && (
              <p
                className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${
                  isChanged ? "glow-text-mid" : ""
                }`}
              >
                {dealHeader}
              </p>
            )}
            <p
              className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${
                isChanged ? "glow-text-mid" : ""
              }`}
            >
              Walkout Potential: {amountDisplay(walkout_potential)}
            </p>

            {promoter_profit !== 0 && (
              <>
                <p
                  className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${
                    isChanged ? "glow-text-mid" : ""
                  }`}
                >
                  Promoter Profit: {amountDisplay(promoter_profit)}
                </p>
              </>
            )}

            {offer_type === "vs" ? (
              <p
                className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${
                  isChanged ? "glow-text-mid" : ""
                }`}
              >
                Net Expenses: {amountDisplay(split_point - the_deal_amount)}
              </p>
            ) : (
              <p
                className={`pl-2 text-right font-mono text-lg font-semibold text-ku-yellow whitespace-nowrap ${
                  isChanged ? "glow-text-mid" : ""
                }`}
              >
                {promoter_profit === 0
                  ? `${offer_type === "vs" ? "Net" : "Total"} Expenses: ${
                      offer_type === "vs"
                        ? amountDisplay(split_point - the_deal_amount)
                        : amountDisplay(split_point)
                    }`
                  : ""}{" "}
                {promoter_profit / 100 > 0 && `BREAKEVEN`}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
