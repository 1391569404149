import { postEventState } from "@/queries/events";
import { createMessage } from "../queries/bulletin";

export const releaseHold = async (eventId, nextHolderId) => {
  try {
    // Update event state to draft
    await postEventState(eventId, { state: "draft" });

    // Send bulletin board message to next holder if exists
    if (nextHolderId) {
      await createMessage({
        recipient_id: nextHolderId,
        content: `You are now the primary hold for event #${eventId}`,
        is_sticky: false,
      });
    }

    return true;
  } catch (error) {
    console.error("Error releasing hold:", error);
    throw error;
  }
};
