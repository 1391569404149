import { useEffect, useState, useRef } from "react";

export const EventsLoading = ({ isLoading, isFadingOut }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center transition-opacity duration-1000 ${
        isFadingOut ? "opacity-0" : "opacity-100"
      }`}
    >
      <div className="relative w-full h-full">
        <video
          ref={videoRef}
          className={`w-full h-full object-cover transition-opacity duration-1000 ${
            videoLoaded ? "opacity-100" : "opacity-0"
          }`}
          src="/static/images/cave_loading25.mp4"
          autoPlay
          muted
          playsInline
          onCanPlay={() => {
            setVideoLoaded(true);
            if (videoRef.current) {
              videoRef.current.play();
            }
          }}
        />
      </div>
    </div>
  );
};
