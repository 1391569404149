import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { Table, TableRow } from "../AppTable2";
import SettlementTableTitle from "./SettlementTableTitle";
import SettlementVerticalTable from "./SettlementVerticalTable";
import { useFinalFigures } from "@/hooks/useFinalFigures";
import { SettlementTab } from "@/types/settlement";
import { amountDisplay } from "@/utils/money";
import { getExpensesTotal } from "@/helpers";

const SettlementRevenuesPL = () => {
  const {
    event,
    rollups,
    expensesQuery,
    variables,
    eventSettlement,
    pageIndex,
    isPDF,
    settlementInputClassName,
  } = useSettlementComponent({ enableExpenses: true });
  const artistFinalFigures = useFinalFigures("artist");
  const coproFinalFigures = useFinalFigures("copro");
  const finalFinalFigures = useFinalFigures("final");
  if (!artistFinalFigures || !coproFinalFigures || !finalFinalFigures)
    return null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const figuresMapped: Record<SettlementTab, { dataToUse: any }> = {
    artist: artistFinalFigures,
    copro: coproFinalFigures,
    final: finalFinalFigures,
  };
  const netTicketSales = event.data?.manifests.reduce(
    (acc, curr) => acc + (curr.price + curr.ticket_fees) * curr.paid,
    0
  );

  // Data for Promoter Revenues
  const promoterRevenuesSection = {
    title: "Promoter Revenues",
    headers: [
      {
        title: "REVENUES",
        accessor: "revenue",
        key: "revenues",
        addRowEditable: true,
        addRowPlaceholder: "Revenue Title",
      },
      {
        title: "TOTAL",
        accessor: "total",
        editable: (row, col) => {
          if (row.key === "net_ticket_sales") return false;
        },
        format: "money",
        addRowPlaceholder: "Revenue Total",
      },
      {
        title: "PER CAP",
        accessor: "per_cap",
        addRowEditable: true,
        addRowPlaceholder: "Revenue Per Cap",
      },
    ],
    data: [
      {
        revenue: "NET TICKET SALES",
        key: "net_ticket_sales",
        total: netTicketSales,
        per_cap: 58.49,
        editable: false,
      },
      {
        revenue: "TXT REBATES",
        // final figures ticket rebates, need to consult with Jesse about this
        total: 22300.6,
        per_cap: 8.91,
      },
      {
        revenue: "FAC FEE",
        total: figuresMapped[pageIndex].dataToUse("facility_fee"),
        per_cap: figuresMapped[pageIndex].dataToUse("facility_fee"),
      },
      {
        revenue: "CONCESSION REVENUE",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "MERCH REVENUE",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "TXTMASTER REVENUE",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "VIP",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "MISC",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "MISC",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
    ],
  };
  const promoterRevnuesTotal = promoterRevenuesSection.data.reduce(
    (acc, curr) => acc + curr.total,
    9
  );
  const showPnLFinalData = [
    {
      key: "guarantee",
      label: "GUARANTEE",
      value: amountDisplay(variables.data?.the_deal_amount ?? 0),
      isEditable: false,
      format: "money",
    },
    {
      key: "venue_copro_share",
      label: "VENUE COPRO SHARE",
      value: "$(125,000.00)",
      isEditable: false,
    },
    {
      key: "partner_1_copro_share",
      label: "PARTNER 1 COPRO SHARE",
      value: "$(125,000.00)",
      isEditable: false,
    },
    {
      key: "partner_2_copro_share",
      label: "PARTNER 2 COPRO SHARE",
      value: "$(125,000.00)",
      isEditable: false,
    },
    {
      key: "adjustments",
      label: "Adjustments",
      value: "$(125,000.00)",
      isEditable: true,
    },
    {
      key: "other",
      label: "Other",
      value: "$(125,000.00)",
      isEditable: true,
    },
  ];
  // Data for Show P&L
  const showPnLData = [
    {
      key: "ticketRev",
      label: "Ticketing Revenue",
      value: netTicketSales,
      format: "money",
      isEditable: false,
    },
    {
      key: "ancillaryRev",
      label: "Ancillary Revenue",
      value: amountDisplay(promoterRevnuesTotal - netTicketSales),
      format: "money",
      isEditable: false,
    },
    {
      key: "expenses",
      label: "Expenses",
      value: amountDisplay(getExpensesTotal(expensesQuery.data ?? [])),
      format: "money",
      isEditable: false,
    },
    { key: "overages", label: "Overages", value: "$0.00", isEditable: false },
    { key: "bonus", label: "Bonus", value: "$0.00", isEditable: false },
    {
      key: "deductions",
      label: "Deductions from Guarantee",
      value: "$0.00",
      isEditable: false,
    },
    ...(pageIndex === "final" ? showPnLFinalData : []),
  ];

  return (
    <div className="mb-8">
      {/* Flexbox SettlementRevenuesPL for Promoter Revenues and Show P&L */}
      <div className="flex flex-wrap lg:flex-nowrap gap-6 mb-8">
        <div className="w-full lg:w-2/3">
          <SettlementTableTitle>Promoter Revenues</SettlementTableTitle>
          <Table
            columns={promoterRevenuesSection.headers}
            data={promoterRevenuesSection.data}
            enableAddRow={!isPDF}
            addRowButtonTitle={!isPDF ? "Add Revenue" : undefined}
            inputClassName={settlementInputClassName}
            isPDF={isPDF}
            deleteIcons={!isPDF}
            onDelete={
              !isPDF
                ? (index, row) => {
                    console.log("Delete row:", row);
                    // If you need actual delete functionality, use deleteExpenseMutation here
                  }
                : undefined
            }
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                format: "money",
              },
            ]}
            rowData={{
              name: "TOTAL REVENUES",
              value: amountDisplay(promoterRevnuesTotal),
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
        <div className="w-full lg:w-1/3">
          <SettlementVerticalTable
            title="Show P&L"
            data={showPnLData}
            onBlur={(key, newValue) => {
              console.log(`Updated Show P&L - Field ${key}: ${newValue}`);
            }}
            valueClass="text-right pr-4"
            labelClass="pl-1 whitespace-nowrap"
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
                width: "65%",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                format: "money",
              },
            ]}
            rowData={{
              name: "PROMOTER PROFIT (LOSS)",
              value: amountDisplay(
                showPnLData.reduce((acc, curr) => {
                  return (
                    acc + (isNaN(Number(curr.value)) ? 0 : Number(curr.value))
                  );
                }, 0)
              ),
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
      </div>
    </div>
  );
};

export default SettlementRevenuesPL;
