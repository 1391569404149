import { useState, useEffect } from "react";

// Define TailwindCSS breakpoints
const breakpoints = {
  xs: 0, // Below Tailwind's smallest breakpoint
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

type BreakpointName = keyof typeof breakpoints;

// Get an array of breakpoint names for easier comparisons
const breakpointNames = Object.keys(breakpoints);

const getBreakpoint = (width: number) => {
  if (width < breakpoints.sm) return "xs";
  if (width < breakpoints.md) return "sm";
  if (width < breakpoints.lg) return "md";
  if (width < breakpoints.xl) return "lg";
  if (width < breakpoints["2xl"]) return "xl";
  return "2xl";
};

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakpoint(window.innerWidth)
  );

  // Update breakpoint dynamically on window resize
  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpoint(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Helper functions
  const isLessThan = (breakpointName: BreakpointName) =>
    breakpointNames.indexOf(breakpoint) <
    breakpointNames.indexOf(breakpointName);

  const isEqualTo = (breakpointName: BreakpointName) =>
    breakpoint === breakpointName;

  const isLessOrEqual = (breakpointName: BreakpointName) =>
    breakpointNames.indexOf(breakpoint) <=
    breakpointNames.indexOf(breakpointName);

  const isGreaterOrEqual = (breakpointName: BreakpointName) =>
    breakpointNames.indexOf(breakpoint) >=
    breakpointNames.indexOf(breakpointName);

  const isGreaterThan = (breakpointName: BreakpointName) =>
    breakpointNames.indexOf(breakpoint) >
    breakpointNames.indexOf(breakpointName);

  return {
    breakpoint,
    isLessThan,
    isEqualTo,
    isLessOrEqual,
    isGreaterOrEqual,
    isGreaterThan,
  };
};

export default useBreakpoint;
