import React, { useCallback, useState, useEffect } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { Disclosure, Transition } from "@headlessui/react";
import Button from "@/components/Button";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";
import { createFile, deleteFile, getFiles } from "@/queries/files";

const ExpenseImage = ({
  image,
  entityType,
  editable,
  parentId,
  bgColor = "bg-cave-blue3",
}) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: () => deleteFile(entityType, parentId, image),
    onMutate: async () => {
      await queryClient.cancelQueries([entityType, "files", parentId]);
      const previousFiles = queryClient.getQueryData([
        entityType,
        "files",
        parentId,
      ]);

      // Optimistic update
      queryClient.setQueryData([entityType, "files", parentId], (old) => {
        if (!old) return { data: [] };
        return {
          ...old,
          data: old.data.filter((file) => file.id !== image.id),
        };
      });

      return { previousFiles };
    },
    onError: (error, variables, context) => {
      // Rollback on error
      if (context?.previousFiles) {
        queryClient.setQueryData(
          [entityType, "files", parentId],
          context.previousFiles
        );
      }
      console.error(`Error deleting image:`, error);
      setDeleteError(
        error.message || "An error occurred while deleting the image."
      );
      console.error("Failed to delete receipt");
    },
    onSuccess: () => {
      setDeleteConfirm(false);
      setDeleteError(null);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([entityType, "files", parentId]);
    },
  });

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteConfirm(true);
    setDeleteError(null);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    deleteMutation.mutate();
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setDeleteConfirm(false);
    setDeleteError(null);
  };

  return (
    <tr
      className={`flex w-full hover:bg-gray-100 dark:hover:bg-gray-800 ${bgColor}`}
    >
      <td className="p-4 border border-rounded border-cave-white w-2/3 flex items-center rounded-md">
        <img
          src={image.file}
          alt={image.name || "Receipt image"}
          className="w-24 h-24 mr-4 rounded object-cover"
          onError={(e) => {
            console.error("Image failed to load:", image);
            e.target.src = "/placeholder-image.png"; // Add a placeholder image if needed
          }}
        />
        <span
          className="text-sm text-blue-200 hover:text-white cursor-pointer underline"
          onClick={() => {
            if (image.file) {
              window.open(image.file, "_blank");
            } else {
              console.error("No URL available for image:", image);
              console.error("Unable to open image: URL not available");
            }
          }}
        >
          {image.name}
        </span>
      </td>
      <td className="p-4 border border-rounded border-cave-white w-1/3 rounded-md">
        <div className="flex justify-between items-center">
          {editable && !deleteConfirm && (
            <TrashIcon
              className="h-6 w-6 text-cave-white hover:text-red-400 cursor-pointer"
              onClick={handleDeleteClick}
            />
          )}
        </div>
        {deleteConfirm && (
          <div className="flex justify-between items-center mt-2">
            <span className="text-red-600">Delete this receipt?</span>
            <div>
              <button
                className="mr-2 rounded bg-red-600 hover:bg-red-700 px-3 py-1 text-cave-white text-sm"
                onClick={handleConfirmDelete}
              >
                Yes
              </button>
              <button
                className="rounded bg-cave-black hover:bg-gray-600 px-3 py-1 text-cave-white text-sm"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        )}
        {deleteError && (
          <div className="mt-2 text-red-600 text-sm">Error: {deleteError}</div>
        )}
      </td>
    </tr>
  );
};

const ExpenseImageUpload = ({ id, entityType, editable }) => {
  const [defaultOpen, setDefaultOpen] = useState(true);

  const {
    data: images,
    isError,
    error,
  } = useQuery(
    ["accounting", entityType, "files", id],
    async () => {
      if (!id) return { data: [] };
      console.log("Fetching files for:", entityType, id);
      const response = await getFiles(entityType, id);
      console.log("Query response for ID", id, ":", response);
      if (!response || !response.data) {
        console.warn("No data in response for ID", id, ":", response);
        return { data: [] };
      }
      return response;
    },
    {
      enabled: !!id,
      initialData: { data: [] },
      staleTime: 0, // Always consider data stale
      cacheTime: 1000, // Keep in cache for just 1 second
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: 5000, // Refetch every 5 seconds
      select: (data) => ({
        data: Array.isArray(data?.data)
          ? data.data.filter((file) => file && file.id && file.active !== false)
          : [],
      }),
      retry: 2,
      onError: (error) => {
        console.error("Error fetching files:", error);
        console.error("Failed to load receipts");
      },
      onSuccess: (data) => {
        console.log("Query success - files:", data);
      },
    }
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    // Set to open by default if there are images
    const validImages =
      images?.data?.filter((img) => img && img.id && img.active !== false) ||
      [];
    const hasImages = validImages.length > 0;
    console.log("Checking images presence:", {
      hasImages,
      imagesData: images?.data,
      validImagesCount: validImages.length,
    });
    setDefaultOpen(hasImages);
    setIsOpen(hasImages);

    // Force a refetch when component mounts or when disclosure opens/closes
    if (id && entityType) {
      queryClient.invalidateQueries([entityType, "files", id]);
    }
  }, [images?.data, id, entityType]);
  const [showUploadForm, setShowUploadForm] = useState(true);
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const mutation = useMutation({
    mutationFn: async () => {
      if (files.length === 0) return;
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      return createFile(entityType, id, formData);
    },
    onMutate: async () => {
      await queryClient.cancelQueries([entityType, "files"]);
      const previousData = queryClient.getQueriesData([entityType, "files"]);
      return { previousData };
    },
    onSuccess: async (data) => {
      setFiles([]);
      setUploadProgress(0);
      setShowUploadForm(false);

      // Force immediate cache update and refetch
      await queryClient.cancelQueries([entityType, "files", id]);

      // Update cache with new data
      queryClient.setQueryData([entityType, "files", id], (old) => {
        const oldData = old?.data || [];
        const newData = Array.isArray(data) ? data : [data];
        const mergedData = [...oldData, ...newData].filter(
          (file) => file && file.id && file.active !== false
        );
        console.log("Updated cache data:", mergedData);
        return { data: mergedData };
      });

      // Force immediate refetch
      await queryClient.invalidateQueries([entityType, "files", id], {
        refetchType: "active",
      });
      const result = await queryClient.refetchQueries(
        [entityType, "files", id],
        { force: true }
      );
      console.log("Refetch result:", result);
    },
    onError: (error, variables, context) => {
      console.error("Error uploading receipt:", error);
      console.error("Failed to upload receipt");

      // Restore previous data on error
      if (context?.previousData) {
        for (const [queryKey, previousValue] of context.previousData) {
          queryClient.setQueryData(queryKey, previousValue);
        }
      }
    },
    onSettled: async () => {
      console.log("Mutation settled, forcing refetch");
      // Force immediate refetch of the specific query
      await queryClient.invalidateQueries([entityType, "files", id], {
        refetchType: "active",
      });
      const result = await queryClient.refetchQueries(
        [entityType, "files", id],
        {
          force: true,
          throwOnError: true,
        }
      );
      console.log("Final refetch result:", result);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    console.log("Files dropped:", acceptedFiles);
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!files.length) {
      console.error("No receipt selected");
      return;
    }

    setIsSubmitting(true);
    let progress = 0;
    const progressInterval = setInterval(() => {
      progress += 10;
      setUploadProgress(Math.min(progress, 100));
      if (progress >= 100) clearInterval(progressInterval);
    }, 300);

    try {
      await mutation.mutateAsync();
    } finally {
      setIsSubmitting(false);
      clearInterval(progressInterval);
      setUploadProgress(0);
    }
  };

  const imageCount = Array.isArray(images?.data)
    ? images.data.filter((img) => {
        // Log the raw image data first
        console.log("Processing image for count:", img);

        // Explicit type checking for active property
        const isActive = img?.active === true;
        const hasId = Boolean(img?.id);

        const isCountable = hasId && isActive;

        console.log(`Image ${img?.id} validation:`, {
          hasId,
          isActive,
          activeValue: img?.active,
          isCountable,
        });

        return isCountable;
      }).length
    : 0;

  // Detailed logging of the count calculation
  console.log("Count calculation details:", {
    hasData: Boolean(images?.data),
    isArray: Array.isArray(images?.data),
    rawDataLength: images?.data?.length,
    finalCount: imageCount,
    rawData: images?.data,
  });

  useEffect(() => {
    console.log("Current image count:", imageCount);
  }, [imageCount, images?.data]);

  return (
    <div className="flex flex-col mb-6 bg-cave-black rounded-lg shadow-md overflow-hidden">
      <div className="w-full flex justify-between items-center p-6 bg-cave-black cursor-pointer">
        <h3 className="text-xl font-semibold text-cave-white">Receipts</h3>
        {editable && !showUploadForm && (
          <Button
            onClick={() => {
              setShowUploadForm(true);
              setIsOpen(true);
            }}
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Add Receipt
          </Button>
        )}
      </div>

      {showUploadForm && (
        <div className="border-md border-rounded border-cave-white p-6 rounded-md">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div
              {...getRootProps()}
              className="border-2 border-dashed border-cave-white p-6 rounded-md text-center cursor-pointer hover:border-cave-white"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="text-blue-200 font-medium">
                  Drop the receipt here ...
                </p>
              ) : (
                <p className="text-gray-300">
                  Drag 'n' drop receipt here, or click to select
                </p>
              )}
            </div>
            {files.length > 0 && (
              <div className="bg-gray-800 p-4 rounded-lg shadow">
                <h4 className="font-semibold text-gray-100 mb-2">
                  Selected Files:
                </h4>
                <ul className="list-disc pl-5 text-cave-white">
                  {files.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
            {isSubmitting && (
              <div className="bg-gray-800 p-4 rounded-lg shadow">
                <p className="text-gray-100 mb-2">
                  Upload Progress: {uploadProgress}%
                </p>
                <div className="w-full bg-gray-300 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full transition-all"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              </div>
            )}
            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                onClick={() => setShowUploadForm(false)}
                className="px-6 py-2 bg-gray-600 text-gray-100 rounded-md hover:bg-gray-500"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || files.length === 0}
                className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
              >
                Upload
              </Button>
            </div>
          </form>
        </div>
      )}

      <div className="overflow-x-auto">
        <Disclosure
          defaultOpen={defaultOpen}
          open={isOpen}
          onChange={setIsOpen}
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full">
                <table className="w-full text-sm text-left text-gray-100 cursor-pointer hover:bg-gray-600 bg-gray-700 rounded-md">
                  <thead>
                    <tr className="flex w-full">
                      <th scope="col" className="px-6 py-3 w-2/3">
                        {open ? "Receipts" : ""}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 w-1/3 flex justify-between items-center"
                      >
                        <span className="text-gray-100">
                          {!open
                            ? `${imageCount} receipt${
                                imageCount !== 1 ? "s" : ""
                              }`
                            : ""}
                        </span>
                        <span className="ml-auto focus:outline-none">
                          {open ? (
                            <ChevronUpIcon className="h-5 w-5 text-gray-100" />
                          ) : (
                            <ChevronDownIcon className="h-5 w-5 text-gray-100" />
                          )}
                        </span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </Disclosure.Button>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <table className="w-full">
                    <tbody>
                      {console.log("Rendering images section, images:", images)}
                      {console.log("images?.data:", images?.data)}
                      {images?.data &&
                      Array.isArray(images.data) &&
                      images.data.length > 0 ? (
                        images.data.map((image) => (
                          <ExpenseImage
                            key={image.id}
                            image={image}
                            editable={editable}
                            entityType={entityType}
                            parentId={id}
                            bgColor="bg-cave-black"
                          />
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="2"
                            className="px-6 py-4 text-center text-sm text-gray-300 bg-gray-800"
                          >
                            <em>No receipts found</em>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default ExpenseImageUpload;
