import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createEventManifest,
  deleteEventManifest,
  getEvent,
  updateManifest,
} from "@/queries/events";
import { toast } from "react-toastify";
import { CaveEvent, Manifest } from "@/types/event";

/**
 * Custom hook to manage manifests
 */
export const useEventManifests = (eventId: string) => {
  const queryClient = useQueryClient();

  /**
   * Fetch Manifests
   */
  const {
    data: manifests,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery<Manifest[], Error>(["manifests", eventId], () =>
    getEvent(eventId).then((response: CaveEvent) =>
      response.manifests.filter((m) => m.active && m.is_offer)
    )
  );

  /**
   * Add Manifest Mutation
   */
  const addManifestMutation = useMutation<
    Manifest,
    Error,
    Partial<Manifest>,
    unknown
  >(
    (newManifest) =>
      createEventManifest(eventId, {
        ...newManifest,
        eventId,
        is_offer: true,
      }),
    {
      onSuccess: () => {
        //toast.success("Manifest added successfully");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
      onError: () => {
        toast.error("Failed to add manifest");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
    }
  );

  /**
   * Update Manifest Mutation
   */
  const updateManifestMutation = useMutation<
    Manifest,
    Error,
    { id: number | string; updates: Partial<Manifest> },
    unknown
  >(
    async ({ id, updates } = { id: undefined, updates: undefined }) => {
      try {
        if (!id || typeof updates !== "object") {
          console.error("Invalid parameters received:", { id, updates });
          throw new Error("Invalid parameters for manifest update");
        }
        console.log("updateManifestMutation received:", { id, updates });

        if (!id && id !== 0) {
          console.error("Invalid ID received:", id);
          throw new Error("Manifest ID is required");
        }

        if (!updates || typeof updates !== "object") {
          console.error("Invalid updates received:", updates);
          throw new Error("Invalid updates for manifest");
        }

        const manifestId = id.toString();
        console.log("Using manifestId:", manifestId);

        // Find the manifest in the current data
        const manifest = manifests?.find(
          (m) => m.id?.toString() === manifestId
        );

        // Create base update data
        const baseData = {
          event: parseInt(eventId),
          active: true,
          is_offer: true,
          ...updates,
        };

        // Merge with existing manifest data if found
        const cleanUpdates = manifest
          ? {
              ...manifest,
              ...baseData,
              price: String(updates.price ?? manifest.price ?? 0),
              ticket_fees: String(
                updates.ticket_fees ?? manifest.ticket_fees ?? 0
              ),
              sort_order:
                typeof updates.sort_order === "number"
                  ? updates.sort_order
                  : manifest.sort_order,
            }
          : baseData;

        console.log(
          "Updating manifest:",
          manifestId,
          "with data:",
          cleanUpdates
        );

        return await updateManifest(manifestId, cleanUpdates);
      } catch (error) {
        console.error("Error in updateManifestMutation:", error);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
      onError: (error) => {
        const message =
          error instanceof Error ? error.message : "Failed to update manifest";
        toast.error(message);
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
    }
  );

  /**
   * Delete Manifest Mutation
   */
  const deleteManifestMutation = useMutation<void, Error, string, unknown>(
    (manifestId) => deleteEventManifest(eventId, manifestId),
    {
      onSuccess: () => {
        toast.success("Manifest deleted successfully");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
      onError: () => {
        toast.error("Failed to delete manifest");
        queryClient.invalidateQueries(["manifests", eventId]);
        queryClient.invalidateQueries(["event-detail", eventId]);
        queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
        queryClient.invalidateQueries(["event-rollups", eventId]);
      },
    }
  );

  return {
    manifests,
    isLoading,
    isError,
    error,
    refetch,
    addManifest: addManifestMutation.mutate,
    updateManifest: updateManifestMutation.mutate,
    deleteManifest: deleteManifestMutation.mutate,
  };
};
