import * as yup from "yup";

// Custom error messages
const errorMessages = {
  required: (field) => `${field} is required`,
  email: "Invalid email address",
  phone: "Invalid phone number format",
  url: "Must be a valid URL",
  positive: "Must be a positive number",
  boolean: "Must be true or false",
  min: "Cannot be less than 0",
};

// Custom validation functions
const phoneRegExp = /^[0-9-+()]*$/;
const timeRegExp =
  /^(0?[1-9]|1[0-2])(?::[0-5][0-9])?\s*(?:am|pm|AM|PM)?$|^(?:[01]?[0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;
const dbTimeRegExp = /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

export const advanceSheetSchema = yup.object().shape({
  // Event Info
  // Event Info
  offer_support_1: yup.string().nullable(),
  offer_support_2: yup.string().nullable(),
  headliner_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(
      timeRegExp,
      'Invalid time format. Use formats like "5pm", "5:30pm", "17:30"'
    ),
  headliner_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(
      /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
      "Invalid time format. Must be in 24-hour format (HH:mm:ss)"
    ),
  venue_name: yup.string().nullable(),
  venue_address: yup.string().nullable(),
  venue_phone: yup
    .string()
    .matches(phoneRegExp, errorMessages.phone)
    .nullable(),
  venue_capacity: yup
    .number()
    .typeError("Capacity must be a number")
    .positive(errorMessages.positive)
    .nullable(),
  venue_website: yup
    .string()
    .url(errorMessages.url)
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),
  venue_type: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value)),

  // Schedule time fields
  schedule_venue_access: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_venue_access_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_breakfast_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_breakfast_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_local_load_in: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_local_load_in_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_tour_load_in: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_tour_load_in_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_lunch_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_lunch_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_sound_check: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_sound_check_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_security_meeting: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_security_meeting_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_dinner_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_dinner_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_doors: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_doors_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_set_1_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_set_1_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_intermission: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_intermission_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_set_2_time: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_set_2_time_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_call_back: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_call_back_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  schedule_curfew: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(timeRegExp, "Invalid time format"),
  schedule_curfew_value: yup
    .string()
    .nullable()
    .transform((value) => (value?.trim() === "" ? null : value))
    .matches(dbTimeRegExp, "Invalid time format"),

  // Venue Contacts
  // Venue Contacts
  gm_name: yup.string(),
  gm_phone: yup.string().matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  gm_cell: yup.string().matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  gm_email: yup.string().email("Invalid email format"),

  marketing_name: yup.string(),
  marketing_phone: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  marketing_cell: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  marketing_email: yup.string().email("Invalid email format"),

  box_office_name: yup.string(),
  box_office_phone: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  box_office_cell: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  box_office_email: yup.string().email("Invalid email format"),

  production_name: yup.string(),
  production_phone: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  production_cell: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  production_email: yup.string().email("Invalid email format"),

  catering_name: yup.string(),
  catering_phone: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  catering_cell: yup
    .string()
    .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
  catering_email: yup.string().email("Invalid email format"),

  // Tour Contacts
  tour_contacts: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      phone: yup
        .string()
        .matches(/^[0-9-+()]*$/, "Invalid phone number format"),
      cell: yup.string().matches(/^[0-9-+()]*$/, "Invalid phone number format"),
      email: yup.string().email("Invalid email format"),
    })
  ),

  // Production Requirements
  production_requirements: yup
    .mixed()
    .transform((value) => {
      if (value === "" || value === undefined) return null;
      return value;
    })
    .nullable(),

  // Labor Calls
  labor_calls: yup.object().shape({
    steward: yup.string(),
    hands: yup.string(),
    loaders: yup.string(),
    riggers_up: yup.string(),
    riggers_down: yup.string(),
    electrician: yup.string(),
    wardrobe: yup.string(),
    spot_ops: yup.string(),
    house_lights: yup.string(),
    fly_man: yup.string(),
  }),

  // Phones/Internet
  phones_internet: yup.object().shape({
    phone_needs: yup.string(),
    internet: yup.string(),
  }),

  // Dressing Rooms
  dressing_rooms: yup.object().shape({
    room_one: yup.string(),
    room_two: yup.string(),
    room_three: yup.string(),
    furniture: yup.string(),
  }),

  // Parking
  parking: yup.object().shape({
    trucks: yup.string(),
    buses: yup.string(),
    vans: yup.string(),
    cars: yup.string(),
  }),

  // Towels/Showers/Laundry
  towels_showers_laundry: yup.object().shape({
    laundry_onsite: yup.boolean(),
    showers_onsite: yup.boolean(),
    bath_towels: yup.number().min(0, "Cannot be negative"),
    hand_towels: yup.number().min(0, "Cannot be negative"),
  }),

  // Estimates
  estimates: yup.object().shape({
    catering: yup.number().min(0, "Cannot be negative"),
    hands: yup.number().min(0, "Cannot be negative"),
    production: yup.number().min(0, "Cannot be negative"),
  }),

  // Notes
  notes: yup.object().shape({
    load_in_notes: yup.string(),
    production_notes: yup.string(),
    production_requirements: yup.string(),
    catering_notes: yup.string(),
    hotel_notes: yup.string(),
    parking_notes: yup.string(),
    merch_notes: yup.string(),
    guest_list_notes: yup.string(),
    dressing_room_notes: yup.string(),
    general_notes: yup.string(),
  }),

  // Cash Needs
  cash_needs: yup
    .mixed()
    .transform((value) => {
      if (value === "" || value === undefined) return null;
      return value;
    })
    .nullable(),
});
